import { Controller } from 'stimulus'
import { createChart } from 'lightweight-charts';
import moment from 'moment';
import i18n from '../../initializers/i18n'
import { tooltip } from 'leaflet';


export default class extends Controller {
  static targets = ["chart"]
  static values = {
    data: Array,
    time: Array,
    channels: Array,
    }
    
  connect(){
    this.seriesAPI = new Map();
    this.seriesData = new Map();
    let valveGraph = typeof container_valve !== 'undefined' ? true : false
    const chartOptions = { 
      layout: { 
        textColor: '#333333', 
        background: { 
          type: 'solid', 
          color: 'white' 
        },
        fontFamily: 'Ubuntu',
        fontSize: 10
      }, 
      height: 400, 
      crosshair: {
        mode: "normal",
        horzLine: {
          visible: false,
          labelVisible: false,
        }
      },
      rightPriceScale:{
        visible: !valveGraph,
        borderVisible:false,
      },
      leftPriceScale:{
        visible:true,
        borderVisible:false,
      },
      priceScale: {
        position: 'both'
      },
      timeScale: {
          timeVisible: true,
          secondsVisible: true,
          fixLeftEdge:true,
          fixRightEdge:true,
          borderVisible: false
        },
        grid: {
          vertLines: {
            color: 'rgba(42, 46, 57, 0)',
        },
        horzLines: {
            color: '#f0f0f0',
          },
        },
    };
    
    const chart = createChart(document.getElementById('chart'), chartOptions);
    this.chart = chart


    let dates = this.timeValue.map((d) => {
      return { value: d.value, time: this.timeToTz(d.time) };
    });
    let uniqueDatesArray = [];
    let seenTimestamps = new Set();
    
    dates.forEach((obj) => {
      if (!seenTimestamps.has(obj.time)) {
        uniqueDatesArray.push(obj);
        seenTimestamps.add(obj.time);
      }
    });

    const datesChart  = chart.addLineSeries({
      color:'white',
      lineWidth: '1',
      priceScaleId:'none',
      lastValueVisible: false,
      priceLineVisible: false,
      crosshairMarkerVisible: false,
    });
    datesChart.setData(uniqueDatesArray)

    const container = document.getElementById('container');

    const toolTipWidth = 80;
    const toolTipHeight = 80;
    const toolTipMargin = 24;

    // Create and style the tooltip html element
    const toolTip = document.createElement('div');
    toolTip.style = `width: 120px; height: auto; position: absolute; display: none; padding: 8px; box-sizing: border-box; font-size: 12px; text-align: left; z-index: 1000; top: 10px; left: 10px; pointer-events: none; border: 1px solid; border-radius: 8px;font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;`;
    
    toolTip.style.color = 'black';
    
   
    toolTip.style.borderColor = '#c5c5c5';
    toolTip.style.background = '#f9f9f9';
    toolTip.style.display = 'none';
    container.appendChild(toolTip);
    let values = []
    this.dataValue.map(element => values.push(element))
    values.forEach((array,index) => {
      //let element = []
      // if(array["logs"].length > 0 ){
      //   array["logs"].map(logs => {
      //     element = [...element, ...(JSON.parse(logs))]
      //   })
      // }
      const element = array["logs"].length > 0 ? JSON.parse(array["logs"]) : []
      const color = array["color"]
      const id = array["adf_id"]
      const data = this.fix_timestamp(element.map(d =>{ return {value: d.pressure ? d.pressure : undefined , time: this.timeToTz(d.created_at)}}));
      const mediumPressure = this.fix_timestamp(element.map((d)=>{ return {value: d.real_setpoint ? d.real_setpoint : undefined, time: this.timeToTz(d.created_at)}}));
      const temperature = this.fix_timestamp(element.map((d)=>{ return {value: d.temperature ? d.temperature : undefined, time: this.timeToTz(d.created_at)}}));
      const battery = this.fix_timestamp(element.map((d)=>{ return {value: d.battery ? d.battery : undefined , time: this.timeToTz(d.created_at)}}));
      const cwj_totalizer = this.fix_timestamp(element.map((d)=>{ return {value: d.cwj_totalizer, time: this.timeToTz(d.created_at)}}));
      const cwj_zmc_flow = this.fix_timestamp(element.map((d)=>{ return {value: d.cwj_zmc_flow, time: this.timeToTz(d.created_at)}}));
      const cwj_clients_sum = this.fix_timestamp(element.map((d)=>{ return {value: d.cwj_clients_sum, time: this.timeToTz(d.created_at)}}));
      const cwj_clients_dif = this.fix_timestamp(element.map((d)=>{ return {value: d.cwj_clients_dif, time: this.timeToTz(d.created_at)}}));
      const totalizer_flow = this.fix_timestamp(element.map((d)=>{ return {value: d.totalizer_flow, time: this.timeToTz(d.created_at)}}));
      const zmc_flow = this.fix_timestamp(element.map((d)=>{ return {value: d.zmc_flow, time: this.timeToTz(d.created_at)}}));
      const plc_totalizer_flow = this.fix_timestamp(element.map((d)=>{ return {value: d.plc_totalizer_flow, time: this.timeToTz(d.created_at)}}));
      const passive = this.fix_timestamp(element.map((d)=>{ return {value: d.passive ? parseFloat(d.passive) : undefined, time: this.timeWithoutTz(d.created_at)}}));
      const reactive = this.fix_timestamp(element.map((d)=>{ return {value: d.reactive, time: this.timeWithoutTz(d.created_at)}}));
      const autoAlg = this.fix_timestamp(element.map((d)=>{ return {value: d.auto_alg, time: this.timeWithoutTz(d.created_at)}}));
      
      //TODO: put this chart without line

    this.seriesData.set(id, array)
    var mediumPressureChart, lineChart, passiveChart, reactiveChart, autoAlgChart;
    if(valveGraph){
      mediumPressureChart  = chart.addLineSeries({
        color:'#FFA841',
        lineWidth: '1px',
        priceScaleId:'left',
        lineStyle: 3,
        lastValueVisible: false,
        priceLineVisible: false,
      }); 
      mediumPressureChart.setData(mediumPressure);
  
      lineChart  = chart.addLineSeries({
        color:'blue',
        lineWidth: '1px',
        priceScaleId:'left',
        entireTextOnly: "bar",
        lastValueVisible: false,
        priceLineVisible: false,
      });
      
      lineChart.setData(data);

      passiveChart  = chart.addLineSeries({
        color:'green',
        lineWidth: '1px',
        priceScaleId:'left',
        entireTextOnly: "bar",
        lastValueVisible: false,
        priceLineVisible: false,
      });
      
      passiveChart.setData(passive);
      
      // reactiveChart  = chart.addLineSeries({
      //   color:'red',
      //   lineWidth: '1px',
      //   priceScaleId:'left',
      //   entireTextOnly: "bar",
      //   lastValueVisible: false,
      //   priceLineVisible: false,
      // });
      
      // reactiveChart.setData(reactive);

      autoAlgChart  = chart.addLineSeries({
        color:'#A855F7',
        lineWidth: '1px',
        priceScaleId:'left',
        entireTextOnly: "bar",
        lastValueVisible: false,
        priceLineVisible: false,
      });
      
      autoAlgChart.setData(autoAlg);
  
      if(data.length == 0){
        $(".empty-chart").show()
      }else{
        $(".empty-chart").hide()
      }

    } else {
      let side = ['left', 'right']
      let style = [0, 3]
      let count = 0
      //Pressure
      if (element[0]){
        if("pressure" in element  || "real_setpoint" in element[0] || "passive" in element[0] || "reactive" in element[0] || "auto_alg" in element[0]){
          // count = this.channelsValue.indexOf("pressure")
          const pressure_count = this.channelsValue.indexOf("pressure")
          const pressureLine = chart.addLineSeries({
            color: color,
            lineWidth: '1px',
            priceScaleId: side[count],
            lineStyle: style[pressure_count],
            entireTextOnly: "bar",
            lastValueVisible: false,
            priceLineVisible: false,
          })
          pressureLine.setData(data); 
          this.seriesAPI.set("pressure_"+ id, pressureLine)
          $("#closed_channel_" + count).find(".string").text("Pressão")
          $("#open_channel_" + count).find(".string").text("Pressão")

          const real_setpoint_count = this.channelsValue.indexOf("real_setpoint")
          const setpointLine = chart.addLineSeries({
            priceScaleId: side[count],
            color: color,
            lineWidth: '1px',
            lineStyle: style[real_setpoint_count],
            lastValueVisible: false,
            priceLineVisible: false,
          })
          setpointLine.setData(mediumPressure);
          this.seriesAPI.set("real_setpoint_"+ id, setpointLine)

          $("#closed_channel_" + count).find(".string").text("Pressão Real")
          $("#open_channel_" + count).find(".string").text("Pressão Real")

          const passive_count = this.channelsValue.indexOf("passive")
          const passiveLine = chart.addLineSeries({
            priceScaleId: side[count],
            color: color,
            lineWidth: '1px',
            lineStyle: style[passive_count],
            lastValueVisible: false,
            priceLineVisible: false,
          })
          passiveLine.setData(passive);
          this.seriesAPI.set("passive_"+ id, passiveLine)

          $("#closed_channel_" + count).find(".string").text("Passivo")
          $("#open_channel_" + count).find(".string").text("Passivo")

          const reactive_count = this.channelsValue.indexOf("reactive")
          const reactiveLine = chart.addLineSeries({
            priceScaleId: side[count],
            color: color,
            lineWidth: '1px',
            lineStyle: style[reactive_count],
            lastValueVisible: false,
            priceLineVisible: false,
          })
          reactiveLine.setData(reactive);
          this.seriesAPI.set("reactive_"+ id, reactiveLine)

          $("#closed_channel_" + count).find(".string").text("Reativo")
          $("#open_channel_" + count).find(".string").text("Reativo")

          const auto_alg_count = this.channelsValue.indexOf("auto_alg")
          const autoAlgLine = chart.addLineSeries({
            priceScaleId: side[count],
            color: color,
            lineWidth: '1px',
            lineStyle: style[auto_alg_count],
            lastValueVisible: false,
            priceLineVisible: false,
          })
          autoAlgLine.setData(autoAlg);
          this.seriesAPI.set("auto_alg_"+ id, autoAlgLine)

          $("#closed_channel_" + count).find(".string").text("Reativo")
          $("#open_channel_" + count).find(".string").text("Reativo")
          $("#"+ side[count]+"_unit").text("bar")
        } else {
          if("pressure" in element[0]){
            count = this.channelsValue.indexOf("pressure")
            $(".chart-legend").show()
            $("#closed_channel_" + count).find(".string").text("Pressão")
            $("#open_channel_" + count).find(".string").text("Pressão")
            $(document).on('render_async_load', function(event) {
              $(".datagrid").children(1).find(".pressure").addClass("datagrid__highlight")
            });
            $("#"+ side[count]+"_unit").text("bar")
            const pressureLine = chart.addLineSeries({
              color: color,
              lineWidth: '1px',
              priceScaleId: side[count],
              lineStyle: style[count],
              entireTextOnly: "bar",
              lastValueVisible: false,
              priceLineVisible: false,
            });
            pressureLine.setData(data); 
            this.seriesAPI.set("pressure_"+ id, pressureLine)
          }
          
          //Setpoint
          if("real_setpoint" in element[0]){ 
            count = this.channelsValue.indexOf("real_setpoint")
            $(".chart-legend").show()
            $("#closed_channel_" + count).find(".string").text("Pressão Real")
            $("#open_channel_" + count).find(".string").text("Pressão Real")
            $("#"+ side[count]+"_unit").text("bar")
            const setpointLine = chart.addLineSeries({
              color: color,
              lineWidth: '1px',
              priceScaleId: side[count],
              lineStyle: style[count++],
              lastValueVisible: false,
              priceLineVisible: false,
            }).setData(mediumPressure);
            this.seriesAPI.set("real_setpoint_"+ id, setpointLine)
          }
        }
        //passive

        //reactive
        // if("reactive" in element[0]){ 
        //   count = this.channelsValue.indexOf("reactive")
        //   $(".chart-legend").show()
        //   $("#closed_channel_" + count).find(".string").text("Reativo")
        //   $("#open_channel_" + count).find(".string").text("Reativo")
        //   $("#"+ side[count]+"_unit").text("bar")
        //   const reactiveLine = chart.addLineSeries({
        //     color: color,
        //     lineWidth: '1px',
        //     priceScaleId: side[count],
        //     lineStyle: style[count++],
        //     lastValueVisible: false,
        //     priceLineVisible: false,
        //   }).setData(reactive);
        //   this.seriesAPI.set("reactive_"+ id, reactiveLine)
        // }

        // //reactive
        // if("auto_alg" in element[0]){ 
        //   count = this.channelsValue.indexOf("auto_alg")
        //   $(".chart-legend").show()
        //   $("#closed_channel_" + count).find(".string").text("Automático")
        //   $("#open_channel_" + count).find(".string").text("Automático")
        //   $("#"+ side[count]+"_unit").text("bar")
        //   const autoAlgLine = chart.addLineSeries({
        //     color: color,
        //     lineWidth: '1px',
        //     priceScaleId: side[count],
        //     lineStyle: style[count++],
        //     lastValueVisible: false,
        //     priceLineVisible: false,
        //   }).setData(autoAlg);
        //   this.seriesAPI.set("autoAlg_"+ id, autoAlgLine)
        // } 
        //Temperature
        if("temperature" in element[0]){ 
          count = this.channelsValue.indexOf("temperature")
          $(".chart-legend").show()
          $("#closed_channel_" + count).find(".string").text("Temperatura")
          $("#open_channel_" + count).find(".string").text("Temperatura")
          $(document).on('render_async_load', function(event) {
            $(".datagrid").children(1).find(".temperature").addClass("datagrid__highlight")
          });
          $("#"+ side[count]+"_unit").text("ºC")
          const temperatureLine = chart.addLineSeries({
            color: color,
            lineWidth: '1px',
            priceScaleId: side[count],
            lineStyle: style[count++],
            entireTextOnly: "ºC",
            lastValueVisible: false,
            priceLineVisible: false,
          })
          temperatureLine.setData(temperature);
          this.seriesAPI.set("temperature_"+ id, temperatureLine)

        }      
        //Battery
        if("battery" in element[0]){ 
          count = this.channelsValue.indexOf("battery")
          $(".chart-legend").show()
          $("#closed_channel_" + count).find(".string").text("Bateria")
          $("#open_channel_" + count).find(".string").text("Bateria")
          $(document).on('render_async_load', function(event) {
            $(".datagrid").children(1).find(".battery").addClass("datagrid__highlight")
          });
          $("#"+ side[count]+"_unit").text("%")
          const batteryLine = chart.addLineSeries({
            color: color,
            lineWidth: '1px',
            priceScaleId: side[count],
            lineStyle: style[count++],
            lastValueVisible: false,
            priceLineVisible: false,
          })
          batteryLine.setData(battery);
          this.seriesAPI.set("battery_"+ id, batteryLine)
        }
        
        const array = ['cwj_totalizer', 'cwj_zmc_flow', 'cwj_clients_sum', 'cwj_clients_dif', "totalizer_flow", "zmc_flow", "plc_totalizer_flow"]
        if("cwj_totalizer" in element[0] && element[0].cwj_totalizer !== null){ 
          count = this.channelsValue.indexOf("cwj_totalizer")
          $(".chart-legend").show()
          $("#closed_channel_" + count).find(".string").text("CWJ Totalizer")
          $("#open_channel_" + count).find(".string").text("CWJ Totalizer")
          $(document).on('render_async_load', function(event) {
            $(".datagrid").children(1).find(".flow").addClass("datagrid__highlight")
          });
          let exists = count
          if(count != 0 && array.includes(this.channelsValue[0])){
            exists = count - 1
          }
          if(count !== exists || (!array.includes(this.channelsValue[0] && count != 0))){
            $("#"+ side[count]+"_unit").text("m3/h")
          }

          const totalizerLine = chart.addLineSeries({
            color: color,
            lineWidth: '1px',
            priceScaleId: side[count],
            lineStyle: style[count],
            lastValueVisible: false,
            priceLineVisible: false,
          })
          totalizerLine.setData(cwj_totalizer);
          this.seriesAPI.set("cwj_totalizer_flowmeter", totalizerLine)
        } 
        if("cwj_zmc_flow" in element[0] && element[0].cwj_zmc_flow !== null){
          count = this.channelsValue.indexOf("cwj_zmc_flow")
          $(".chart-legend").show()
          $("#closed_channel_" + count).find(".string").text("CWJZmcFlow")
          $("#open_channel_" + count).find(".string").text("CWJZmcFlow")
          $(document).on('render_async_load', function(event) {
            $(".datagrid").children(1).find(".cwj_zmc_flow").addClass("datagrid__highlight")
          });
          let exists = count
          if(count != 0 && array.includes(this.channelsValue[0])){
            exists = count - 1
          }
          if(count !== exists || (!array.includes(this.channelsValue[0] && count != 0))){
            $("#"+ side[exists]+"_unit").text("m3/h")
          }
          const zmcFlowLine = chart.addLineSeries({
            color: color,
            lineWidth: '1px',
            priceScaleId: side[exists],
            lineStyle: style[count],
            lastValueVisible: false,
            priceLineVisible: false,
          })
          zmcFlowLine.setData(cwj_zmc_flow);
          this.seriesAPI.set("cwj_zmc_flow_flowmeter", zmcFlowLine)
        }
        
        if("cwj_clients_sum" in element[0] && element[0].cwj_clients_sum !== null){
          count = this.channelsValue.indexOf("cwj_clients_sum")
          $(".chart-legend").show()
          $("#closed_channel_" + count).find(".string").text("CWJClientsSum")
          $("#open_channel_" + count).find(".string").text("CWJClientsSum")
          $(document).on('render_async_load', function(event) {
            $(".datagrid").children(1).find(".cwj_clients_sum").addClass("datagrid__highlight")
          });
          let exists = count
          if(count != 0 && array.includes(this.channelsValue[0])){
            exists = count + 1
          }
          if(count !== exists || (!array.includes(this.channelsValue[0] && count != 0))){
            $("#"+ side[exists]+"_unit").text("m3/h")
          }
          const clientsSumLine = chart.addLineSeries({
            color: color,
            lineWidth: '1px',
            priceScaleId: side[exists],
            lineStyle: style[count],
            lastValueVisible: false,
            priceLineVisible: false,
          })
          clientsSumLine.setData(cwj_clients_sum);
          this.seriesAPI.set("cwj_clients_sum_flowmeter", clientsSumLine)
        }

        if("cwj_clients_dif" in element[0] && element[0].clients_dif !== null){ 
          count = this.channelsValue.indexOf("cwj_clients_dif")
          $(".chart-legend").show()
          $("#closed_channel_" + count).find(".string").text("CWJClientsDif")
          $("#open_channel_" + count).find(".string").text("CWJClientsDif")
          $(document).on('render_async_load', function(event) {
            $(".datagrid").children(1).find(".cwj_clients_dif").addClass("datagrid__highlight")
          });
          let exists = count
          if(count != 0 && array.includes(this.channelsValue[0])){
            exists = count - 1
          }
          if(count !== exists || (!array.includes(this.channelsValue[0] && count != 0))){
            $("#"+ side[exists]+"_unit").text("m3/h")
          }
          const clientsDifLine = chart.addLineSeries({
            color: color,
            lineWidth: '1px',
            priceScaleId: side[exists],
            lineStyle: style[count],
            lastValueVisible: false,
            priceLineVisible: false,
          })
          clientsDifLine.setData(cwj_clients_dif);
          this.seriesAPI.set("cwj_clients_dif_flowmeter", clientsDifLine)
        }

        if("plc_totalizer_flow" in element[0] && element[0].plc_totalizer_flow !== null){ 
          count = this.channelsValue.indexOf("plc_totalizer_flow")
          $(".chart-legend").show()
          $("#closed_channel_" + count).find(".string").text("PLCTotalizerFlow")
          $("#open_channel_" + count).find(".string").text("PLCTotalizerFlow")
          $(document).on('render_async_load', function(event) {
            $(".datagrid").children(1).find(".plc_totalizer_flow").addClass("datagrid__highlight")
          });
          let exists = count
          if(count != 0 && array.includes(this.channelsValue[0])){
            exists = count - 1
          }
          if(count !== exists || (!array.includes(this.channelsValue[0] && count != 0))){
            $("#"+ side[exists]+"_unit").text("m3")
          }
          const PLCTotalizerFlowLine = chart.addLineSeries({
            color: color,
            lineWidth: '1px',
            priceScaleId: side[exists],
            lineStyle: style[count],
            lastValueVisible: false,
            priceLineVisible: false,
          })
          PLCTotalizerFlowLine.setData(plc_totalizer_flow);
          this.seriesAPI.set("plc_totalizer_flow_flowmeter", PLCTotalizerFlowLine)
        }

        if("totalizer_flow" in element[0] && element[0].totalizer_flow !== null){ 
          count = this.channelsValue.indexOf("totalizer_flow")
          $(".chart-legend").show()
          $("#closed_channel_" + count).find(".string").text("TotalizerFlow")
          $("#open_channel_" + count).find(".string").text("TotalizerFlow")
          $(document).on('render_async_load', function(event) {
            $(".datagrid").children(1).find(".totalizer_flow").addClass("datagrid__highlight")
          });
          let exists = count
          if(count != 0 && array.includes(this.channelsValue[0])){
            exists = count - 1
          }
          if(count !== exists || (!array.includes(this.channelsValue[0] && count != 0))){
            $("#"+ side[exists]+"_unit").text("m3")
          }
          const totalizerFlowLine = chart.addLineSeries({
            color: color,
            lineWidth: '1px',
            priceScaleId: side[exists],
            lineStyle: style[count],
            lastValueVisible: false,
            priceLineVisible: false,
          })
          totalizerFlowLine.setData(totalizer_flow);
          this.seriesAPI.set("totalizer_flow_flowmeter", totalizerFlowLine)
        }

        if("zmc_flow" in element[0] && element[0].zmc_flow !== null){ 
          count = this.channelsValue.indexOf("zmc_flow")
          $(".chart-legend").show()
          $("#closed_channel_" + count).find(".string").text("ZMCFlow")
          $("#open_channel_" + count).find(".string").text("ZMCFlow")
          $(document).on('render_async_load', function(event) {
            $(".datagrid").children(1).find(".zmc_flow").addClass("datagrid__highlight")
          });
          let exists = count
          if(count != 0 && array.includes(this.channelsValue[0])){
            exists = count - 1
          }
          if(count !== exists || (!array.includes(this.channelsValue[0] && count != 0))){
            $("#"+ side[count]+"_unit").text("m3/h")
          }
          const calculatedInstantFlowLine = chart.addLineSeries({
            color: color,
            lineWidth: '1px',
            priceScaleId: side[count],
            lineStyle: style[count],
            lastValueVisible: false,
            priceLineVisible: false,
          })
          calculatedInstantFlowLine.setData(zmc_flow);
          this.seriesAPI.set("zmc_flow_flowmeter", calculatedInstantFlowLine)
        }
      }
      if(this.channelsValue.length == 0){
        $(".empty-chart").show()
      }else{
        $(".empty-chart").hide()
      }
    }
    
    chart.subscribeCrosshairMove(param => {
      if (
        param.point === undefined ||
        !param.time ||
        param.point.x < 0 ||
        param.point.x > container.clientWidth ||
        param.point.y < 0 ||
        param.point.y > container.clientHeight 
      ) {
        toolTip.style.display = 'none';
      } else {
        // time will be in the same format that we supplied to setData.
        // thus it will be YYYY-MM-DD
        var time = this.timeToTz(param.time*1000);
        const dateStr = moment(param.time*1000).format('HH:mm:ss');
        toolTip.style.display = 'none';
        toolTip.innerHTML = ``
        if (valveGraph) { 
          const pressure_data = param.seriesData.get(lineChart);
          if(pressure_data !== undefined){
            const adjusted_pressure_data = param.seriesData.get(mediumPressureChart)
            const pressure = pressure_data.value !== undefined ? pressure_data.value : pressure_data.close;
            const adjusted_pressure = adjusted_pressure_data.value !== undefined ? adjusted_pressure_data.value : adjusted_pressure_data.close;
            toolTip.innerHTML +=
            `<div  style="font-family: ${'Ubuntu'}; font-wight:${'bold'}; font-size: 10px; margin: 4px 0px; color: ${lineChart.options().color};">
              <hr class="line line--real-pressure" style="border-color: ${lineChart.options().color};">
              <div>${ i18n.t("views.common.pressure.title")}: <div>
              <div>${Math.round(100 * pressure) / 100} ${ i18n.t("views.common.pressure.measure")}<div>
            </div>
            <div style="font-family: ${'Ubuntu'}; font-wight:${'bold'}; font-size: 10px; margin: 4px 0px; color: ${mediumPressureChart.options().color};">
              <hr class="line line--adjust-pressure" style="border-color: ${mediumPressureChart.options().color};">
              <div>${ i18n.t("views.common.adjusted_pressure.title")}:<div>
              <div>${Math.round(100 * adjusted_pressure) / 100} ${ i18n.t("views.common.pressure.measure")}</div>
            </div>`
          }
          const passive_data = param.seriesData.get(passiveChart);
          if (passive_data !== undefined) {
            const passive = passive_data.value !== undefined ? passive_data.value : passive_data.close;
            toolTip.innerHTML +=
            `<div style="font-family: ${'Ubuntu'}; font-wight:${'bold'}; font-size: 10px; margin: 4px 0px; color:${passiveChart.options().color}">
              <hr class="line line--real-pressure" style="border-color: ${passiveChart.options().color};">
              <div>${ i18n.t("views.common.passive.title")}: </div>
              <div>${Math.round(100 * passive) / 100} ${ i18n.t("views.common.passive.measure")}</div>
            </div>`
          }
          // const reactive_data = param.seriesData.get(reactiveChart);
          // if (reactive_data !== undefined) {
          //   const reactive = reactive_data.value !== undefined ? reactive_data.value : reactive_data.close;
          //   toolTip.innerHTML +=
          //   `<div style="font-family: ${'Ubuntu'}; font-wight:${'bold'}; font-size: 10px; margin: 4px 0px; color:${reactiveChart.options().color}">
          //     <hr class="line line--real-pressure" style="border-color: ${reactiveChart.options().color};">
          //     <div>${ i18n.t("views.common.reactive.title")}: </div>
          //     <div>${Math.round(100 * reactive) / 100} ${ i18n.t("views.common.reactive.measure")}</div>
          //   </div>`
          // }
          const auto_alg_data = param.seriesData.get(autoAlgChart);
          if (auto_alg_data !== undefined) {
            const auto_alg = auto_alg_data.value !== undefined ? auto_alg_data.value : auto_alg_data.close;
            toolTip.innerHTML +=
            `<div style="font-family: ${'Ubuntu'}; font-wight:${'bold'}; font-size: 10px; margin: 4px 0px; color:${autoAlgChart.options().color}">
              <hr class="line line--real-pressure" style="border-color: ${autoAlgChart.options().color};">
              <div>${ i18n.t("views.common.auto_alg.title")}: </div>
              <div>${Math.round(100 * auto_alg) / 100} ${ i18n.t("views.common.auto_alg.measure")}</div>
            </div>`
          }
          if(toolTip.innerHTML != ``){
            toolTip.style.display = 'block';
          } 
        }else{
          let lineType = ["line", "dashed"]
          this.seriesAPI.forEach((value, key, map) => {
            if(key.includes("pressure") && param.seriesData.get(value)){
              var line = this.channelsValue.indexOf("pressure")
              const pressure_data = param.seriesData.get(value);
              const pressure = pressure_data.value !== undefined ? pressure_data.value : pressure_data.close;
              toolTip.innerHTML +=
              `<div style="font-family: ${'Ubuntu'}; font-wight:${'bold'}; font-size: 10px; margin: 4px 0px; color: ${value.options().color}">
              <hr class="line line--${lineType[line]}" style="border-color: ${value.options().color};">
              <div>${ i18n.t("views.common.pressure.title")}: </div>
              <div>${Math.round(100 * pressure) / 100} ${ i18n.t("views.common.pressure.measure")}</div>
              </div>`
              line = false
            }
            if(key.includes("passive") && param.seriesData.get(value)){
              var line = this.channelsValue.indexOf("passive")
              const passive_data = param.seriesData.get(value);
              const passive = passive_data.value !== undefined ? passive_data.value : passive_data.close;
              toolTip.innerHTML +=
              `<div style="font-family: ${'Ubuntu'}; font-wight:${'bold'}; font-size: 10px; margin: 4px 0px; color:${value.options().color}">
                <hr class="line line--${lineType[line] }" style="border-color: ${value.options().color};">
                <div>${ i18n.t("views.common.passive.title")}: </div>
                <div>${Math.round(100 * passive) / 100} ${ i18n.t("views.common.passive.measure")}</div>
              </div>`
              line = false
            }
            if(key.includes("reactive") && param.seriesData.get(value)){
              var line = this.channelsValue.indexOf("reactive")
              const reactive_data = param.seriesData.get(value);
              const reactive = reactive_data.value !== undefined ? reactive_data.value : reactive_data.close;
              toolTip.innerHTML +=
              `<div style="font-family: ${'Ubuntu'}; font-wight:${'bold'}; font-size: 10px; margin: 4px 0px; color:${value.options().color}">
                <hr class="line line--${lineType[line] }" style="border-color: ${value.options().color};">
                <div>${ i18n.t("views.common.reactive.title")}: </div>
                <div>${Math.round(100 * reactive) / 100} ${ i18n.t("views.common.reactive.measure")}</div>
              </div>`
              line = false
            }

            if(key.includes("autoAlg") && param.seriesData.get(value)){
              var line = this.channelsValue.indexOf("autoAlg")
              const auto_alg_data = param.seriesData.get(value);
              const autoAlg = auto_alg_data.value !== undefined ? auto_alg_data.value : auto_alg_data.close;
              toolTip.innerHTML +=
              `<div style="font-family: ${'Ubuntu'}; font-wight:${'bold'}; font-size: 10px; margin: 4px 0px; color:${value.options().color}">
                <hr class="line line--${lineType[line] }" style="border-color: ${value.options().color};">
                <div>${ i18n.t("views.common.auto_alg.title")}: </div>
                <div>${Math.round(100 * autoAlg) / 100} ${ i18n.t("views.common.auto_alg.measure")}</div>
              </div>`
              line = false
            }

            if(key.includes("temperature") && param.seriesData.get(value)){
              var line = this.channelsValue.indexOf("temperature")
              const temperature_data = param.seriesData.get(value);
              const temperature = temperature_data.value !== undefined ? temperature_data.value : temperature_data.close;
              toolTip.innerHTML +=
              `<div style="font-family: ${'Ubuntu'}; font-wight:${'bold'}; font-size: 10px; margin: 4px 0px; color:${value.options().color}">
                <hr class="line line--${lineType[line] }" style="border-color: ${value.options().color};">
                <div>${ i18n.t("views.common.temperature.title")}: </div>
                <div>${Math.round(100 * temperature) / 100} ${ i18n.t("views.common.temperature.measure")}</div>
              </div>`
              line = false
            }
            if(key.includes("battery") && param.seriesData.get(value)){
              var line = this.channelsValue.indexOf("battery")
              const battery_data = param.seriesData.get(value);
              const battery = battery_data.value !== undefined ? battery_data.value : battery_data.close;
              toolTip.innerHTML +=
              `<div style="font-family: ${'Ubuntu'}; font-wight:${'bold'}; font-size: 10px; margin: 4px 0px; color: ${value.options().color}">
                <hr class="line line--${lineType[line] }" style="border-color: ${value.options().color};">
                <div>${ i18n.t("views.common.battery.title")}: </div>
                <div>${Math.round(100 * battery) / 100} ${ i18n.t("views.common.battery.measure")}</div>
              </div>`
              line = false
            }
            if(key === "cwj_totalizer_flowmeter" && param.seriesData.get(value)){
              var line = this.channelsValue.indexOf("cwj_totalizer")
              const cwj_totalizer_data = param.seriesData.get(value);
              const cwj_totalizer = cwj_totalizer_data.value !== undefined ? cwj_totalizer_data.value : cwj_totalizer_data.close;
              toolTip.innerHTML +=
              `<div style="font-family: ${'Ubuntu'}; font-wight:${'bold'}; font-size: 10px; margin: 4px 0px; color: ${value.options().color}">
                <hr class="line line--${lineType[line]}" style="border-color: ${value.options().color};">
                <div>${ i18n.t("views.common.cwj_totalizer.title")}:</div>
                <div>${Math.round(100 * cwj_totalizer) / 100} ${ i18n.t("views.common.cwj_totalizer.measure")}</div>
              </div>`
              line = false
            }
            if(key.includes("cwj_zmc_flow") && param.seriesData.get(value)){
              var line = this.channelsValue.indexOf("cwj_zmc_flow")
              const cwj_zmc_flow_data = param.seriesData.get(value);
              const cwj_zmc_flow = cwj_zmc_flow_data.value !== undefined ? cwj_zmc_flow_data.value : cwj_zmc_flow_data.close;
              toolTip.innerHTML +=
              `<div style="font-family: ${'Ubuntu'}; font-wight:${'bold'}; font-size: 10px; margin: 4px 0px; color: ${value.options().color}">
                <hr class="line line--${lineType[line]}" style="border-color: ${value.options().color};">
                <div>${ i18n.t("views.common.cwj_zmc_flow.title")}:</div>
                <div>${Math.round(100 * cwj_zmc_flow) / 100} ${ i18n.t("views.common.cwj_zmc_flow.measure")}</div>
              </div>`
              line = false
            }
            if(key.includes("cwj_clients_sum") && param.seriesData.get(value)){
              var line = this.channelsValue.indexOf("cwj_clients_sum")
              const cwj_clients_sum_data = param.seriesData.get(value);
              const cwj_clients_sum = cwj_clients_sum_data.value !== undefined ? cwj_clients_sum_data.value : cwj_clients_sum_data.close;
              toolTip.innerHTML +=
              `<div style="font-family: ${'Ubuntu'}; font-wight:${'bold'}; font-size: 10px; margin: 4px 0px; color: ${value.options().color}">
                <hr class="line line--${lineType[line]}" style="border-color: ${value.options().color};">
                <div>${ i18n.t("views.common.cwj_clients_sum.title")}:</div>
                <div>${Math.round(100 * cwj_clients_sum) / 100} ${ i18n.t("views.common.cwj_clients_sum.measure")}</div>
              </div>`
              line = false
            }
            if(key.includes("cwj_clients_dif") && param.seriesData.get(value)){
              var line = this.channelsValue.indexOf("cwj_clients_dif")
              const cwj_clients_dif_data = param.seriesData.get(value);
              const cwj_clients_dif = cwj_clients_dif_data.value !== undefined ? cwj_clients_dif_data.value : cwj_clients_dif_data.close;
              toolTip.innerHTML +=
              `<div style="font-family: ${'Ubuntu'}; font-wight:${'bold'}; font-size: 10px; margin: 4px 0px; color: ${value.options().color}">
                <hr class="line line--${lineType[line]}" style="border-color: ${value.options().color};">
                <div>${ i18n.t("views.common.cwj_clients_dif.title")}:</div>
                <div>${Math.round(100 * cwj_clients_dif) / 100} ${ i18n.t("views.common.cwj_clients_dif.measure")}</div>
              </div>`
              line = true
            }
            if(key == "totalizer_flow_flowmeter" && param.seriesData.get(value)){
              var line = this.channelsValue.indexOf("totalizer_flow")
              const totalizer_flow_data = param.seriesData.get(value);
              const totalizer_flow_dif = totalizer_flow_data.value !== undefined ? totalizer_flow_data.value : totalizer_flow_data.close;
              toolTip.innerHTML +=
              `<div style="font-family: ${'Ubuntu'}; font-wight:${'bold'}; font-size: 10px; margin: 4px 0px; color: ${value.options().color}">
                <hr class="line line--${lineType[line]}" style="border-color: ${value.options().color};">
                <div>${ i18n.t("views.common.totalizer_flow.title")}:</div>
                <div>${Math.round(100 * totalizer_flow_dif) / 100} ${ i18n.t("views.common.totalizer_flow.measure")}</div>
              </div>`
              line = true
            }
            if(key.includes("zmc_flow") && param.seriesData.get(value)){
              var line = this.channelsValue.indexOf("zmc_flow")
              const zmc_flow_data = param.seriesData.get(value);
              const zmc_flow_dif = zmc_flow_data.value !== undefined ? zmc_flow_data.value : zmc_flow_data.close;
              toolTip.innerHTML +=
              `<div style="font-family: ${'Ubuntu'}; font-wight:${'bold'}; font-size: 10px; margin: 4px 0px; color: ${value.options().color}">
                <hr class="line line--${lineType[line]}" style="border-color: ${value.options().color};">
                <div>${ i18n.t("views.common.zmc_flow.title")}:</div>
                <div>${Math.round(100 * zmc_flow_dif) / 100} ${ i18n.t("views.common.zmc_flow.measure")}</div>
              </div>`
              line = true
            }

            if(key.includes("plc_totalizer_flow_flowmeter") && param.seriesData.get(value)){
              var line = this.channelsValue.indexOf("plc_totalizer_flow")
              const plc_totalizer_flow_data = param.seriesData.get(value);
              const plc_totalizer_flow_dif = plc_totalizer_flow_data.value !== undefined ? plc_totalizer_flow_data.value : plc_totalizer_flow_data.close;
              toolTip.innerHTML +=
              `<div style="font-family: ${'Ubuntu'}; font-wight:${'bold'}; font-size: 10px; margin: 4px 0px; color: ${value.options().color}">
                <hr class="line line--${lineType[line]}" style="border-color: ${value.options().color};">
                <div>${ i18n.t("views.common.plc_totalizer_flow.title")}:</div>
                <div>${Math.round(100 * plc_totalizer_flow_dif) / 100} ${ i18n.t("views.common.plc_totalizer_flow.measure")}</div>
              </div>`
              line = true
            }
          })
          
          if(toolTip.innerHTML != ``){
            toolTip.style.display = 'block';
          }

        }

        var offsets = $("#chart").find("canvas").first().offset();
        var chart_top = offsets.top;
        var chart_left = offsets.left;
        const y = param.point.y;
        let left = param.point.x + toolTipMargin;
        if (left > container.clientWidth - toolTipWidth) {
          left = param.point.x - toolTipMargin - toolTipWidth;
        }
    
        let top = y + toolTipMargin;
        if (top > container.clientHeight - toolTipHeight) {
          top = y - toolTipHeight - toolTipMargin;
        }
        toolTip.style.left = (left +chart_left) + 'px';
        toolTip.style.top = (top + chart_top) + 'px';
      }
    });
  

    chart.priceScale().applyOptions({
      autoScale:false,
      alignLabels:true,
      scaleMargins:{
        bottom:0.5,
        top:0.1
      }
    })
    chart.timeScale().fitContent()
    
    })
    
    }

  disconnect(){

  }

  reconnect(ev){
    //console.log("reconnecting ....")
    // this.disconnect()
    // this.connect()
  }

  switchLineSeries(e) {
    const type = e.srcElement.id
    const adf_id = e.srcElement.value
    const chartAPI =  this.seriesAPI.get(type + adf_id)
    const chart = this.chart
    if(this.seriesAPI.size == this.seriesData.size * this.channelsValue.length){
      $(".checkbox__label").children().prop("checked", false)
    }
    if(chartAPI == undefined){
      const array = this.seriesData.get(adf_id)
      $("#"+ adf_id.replaceAll(":", "\\:")+".card-sensor").find("."+type.substring(0, type.length-1)).show()
      const element = array["logs"].length > 0 ? JSON.parse(array["logs"]) : []
      const color = array["color"]
      const id = array["adf_id"]
      const data = this.fix_timestamp(element.map(d =>{ return {value: d.pressure ? d.pressure : undefined , time: this.timeToTz(d.created_at)}}));
      const mediumPressure = this.fix_timestamp(element.map((d)=>{ return {value: d.real_setpoint ? d.real_setpoint : undefined, time: this.timeToTz(d.created_at)}}));
      const temperature = this.fix_timestamp(element.map((d)=>{ return {value: d.temperature ? d.temperature : undefined, time: this.timeToTz(d.created_at)}}));
      const battery = this.fix_timestamp(element.map((d)=>{ return {value: d.battery ? d.battery : undefined , time: this.timeToTz(d.created_at)}}));
      const cwj_totalizer = this.fix_timestamp(element.map((d)=>{ return {value: d.cwj_totalizer, time: this.timeToTz(d.created_at)}}));
      const cwj_zmc_flow = this.fix_timestamp(element.map((d)=>{ return {value: d.cwj_zmc_flow, time: this.timeToTz(d.created_at)}}));
      const cwj_clients_sum = this.fix_timestamp(element.map((d)=>{ return {value: d.cwj_clients_sum, time: this.timeToTz(d.created_at)}}));
      const cwj_clients_dif = this.fix_timestamp(element.map((d)=>{ return {value: d.cwj_clients_dif, time: this.timeToTz(d.created_at)}}));
      const totalizer_flow = this.fix_timestamp(element.map((d)=>{ return {value: d.totalizer_flow, time: this.timeToTz(d.created_at)}}));
      const zmc_flow = this.fix_timestamp(element.map((d)=>{ return {value: d.zmc_flow, time: this.timeToTz(d.created_at)}}));
      const plc_totalizer_flow = this.fix_timestamp(element.map((d)=>{ return {value: d.plc_totalizer_flow, time: this.timeToTz(d.created_at)}}));
      const passive = this.fix_timestamp(element.map((d)=>{ return {value: d.passive, time: this.timeWithoutTz(d.created_at)}}));
      const reactive = this.fix_timestamp(element.map((d)=>{ return {value: d.reactive, time: this.timeWithoutTz(d.created_at)}}));
      const autoAlg = this.fix_timestamp(element.map((d)=>{ return {value: d.auto_alg, time: this.timeWithoutTz(d.created_at)}}));

      let side = ['left', 'right']
      let style = [0, 3]

      if(type == "pressure_"){
        const count = ($(".chart-legend-units").find("#left_unit").text() == "bar") ? 0 : 1
        const pressureLine = chart.addLineSeries({
          color: color,
          lineWidth: '1px',
          priceScaleId: side[count],
          lineStyle: style[count],
          entireTextOnly: "bar",
          lastValueVisible: false,
          priceLineVisible: false,
        });
        pressureLine.setData(data); 
         this.seriesAPI.set("pressure_"+ id, pressureLine)
      }
      //Setpoint
      if(type == "real_setpoint_"){ 
        const count = ($(".chart-legend-units").find("#left_unit").text() == "bar") ? 0 : 1
        const setpointLine = chart.addLineSeries({
          color: color,
          lineWidth: '1px',
          priceScaleId: side[count],
          lineStyle: style[count],
          lastValueVisible: false,
          priceLineVisible: false,
        }).setData(mediumPressure);
         this.seriesAPI.set("real_setpoint_"+ id, setpointLine)
      }

    //Passive
    if(type == "passive_"){ 
      const count = ($(".chart-legend-units").find("#left_unit").text() == "bar") ? 0 : 1
      const passiveLine = chart.addLineSeries({
        color: color,
        lineWidth: '1px',
        priceScaleId: side[count],
        lineStyle: style[count],
        lastValueVisible: false,
        priceLineVisible: false,
      })
      passiveLine.setData(passive);
       this.seriesAPI.set("passive_"+ id, passiveLine)
    }   
    
    //Reactive
    if(type == "reactive_"){ 
      const count = ($(".chart-legend-units").find("#left_unit").text() == "bar") ? 0 : 1
      const reactiveLine = chart.addLineSeries({
        color: color,
        lineWidth: '1px',
        priceScaleId: side[count],
        lineStyle: style[count],
        lastValueVisible: false,
        priceLineVisible: false,
      })
      reactiveLine.setData(reactive);
        this.seriesAPI.set("reactive_"+ id, reactiveLine)
    }
    //autoLog
    if(type == "autoAlg_"){ 
      const count = ($(".chart-legend-units").find("#left_unit").text() == "bar") ? 0 : 1
      const autoAlgLine = chart.addLineSeries({
        color: color,
        lineWidth: '1px',
        priceScaleId: side[count],
        lineStyle: style[count],
        lastValueVisible: false,
        priceLineVisible: false,
      })
      autoAlgLine.setData(autoAlg);
        this.seriesAPI.set("autoAlg_"+ id, autoAlgLine)
    }

    //Temperature
    if(type == "temperature_"){ 
      const count = ($(".chart-legend-units").find("#left_unit").text() == "ºC") ? 0 : 1
      const temperatureLine = chart.addLineSeries({
        color: color,
        lineWidth: '1px',
        priceScaleId: side[count],
        lineStyle: style[count],
        lastValueVisible: false,
        priceLineVisible: false,
      })
      temperatureLine.setData(temperature);
       this.seriesAPI.set("temperature_"+ id, temperatureLine)
    }      
  
    //Battery
    if(type == "battery_"){ 
      const count = ($(".chart-legend-units").find("#left_unit").text() == "%") ? 0 : 1
      const batteryLine = chart.addLineSeries({
        color: color,
        lineWidth: '1px',
        priceScaleId: side[count],
        lineStyle: style[count],
        lastValueVisible: false,
        priceLineVisible: false,
      });
      batteryLine.setData(battery);
       this.seriesAPI.set("battery_"+ id, batteryLine)
    }

    //Flow
    if(type == "cwj_totalizer_"){ 
      const count = ($(".chart-legend-units").find("#left_unit").text() == "m3") ? 0 : 1
      const CWJtotalizerLine = chart.addLineSeries({
        color: color,
        lineWidth: '1px',
        priceScaleId: side[count],
        lineStyle: style[count],
        lastValueVisible: false,
        priceLineVisible: false,
      });
      CWJtotalizerLine.setData(cwj_totalizer);
      this.seriesAPI.set("cwj_totalizer_flowmeter", CWJtotalizerLine)
    }

    //Flow Sum
    if(type == "cwj_zmc_flow_"){
      const lineType = this.channelsValue.indexOf("cwj_zmc_flow")
      const count = ($(".chart-legend-units").find("#left_unit").text() == "m3/h") ? 0 : 1
      const flowSumLine = chart.addLineSeries({
        color: color,
        lineWidth: '1px',
        priceScaleId: side[count],
        lineStyle: style[lineType],
        lastValueVisible: false,
        priceLineVisible: false,
      });
      flowSumLine.setData(cwj_zmc_flow);
       this.seriesAPI.set("cwj_zmc_flow_flowmeter", flowSumLine)
    }
      //clients Sum
      if(type == "cwj_clients_sum_"){
        const lineType = this.channelsValue.indexOf("cwj_clients_sum")
        const count = ($(".chart-legend-units").find("#left_unit").text() == "m3/h") ? 0 : 1
        $("#"+ side[count]+"_unit").text("m3/h")
        const clientsSumLine = chart.addLineSeries({
          color: color,
          lineWidth: '1px',
          priceScaleId: side[count],
          lineStyle: style[lineType],
          lastValueVisible: false,
          priceLineVisible: false,
        })
        clientsSumLine.setData(cwj_clients_sum);
        this.seriesAPI.set("cwj_clients_sum_flowmeter", clientsSumLine)
      }

      //clients Dif
      if(type == "cwj_clients_dif_"){ 
        const lineType = this.channelsValue.indexOf("cwj_clients_dif")
        const count = ($(".chart-legend-units").find("#left_unit").text() == "m3/h") ? 0 : 1
        const clientsDifLine = chart.addLineSeries({
          color: color,
          lineWidth: '1px',
          priceScaleId: side[count],
          lineStyle: style[lineType],
          lastValueVisible: false,
          priceLineVisible: false,
        })
        clientsDifLine.setData(cwj_clients_dif);
        this.seriesAPI.set("cwj_clients_dif_flowmeter", clientsDifLine)
      }

      if(type == "totalizer_flow_"){ 
        const lineType = this.channelsValue.indexOf("totalizer_flow")
        const count = ($(".chart-legend-units").find("#left_unit").text() == "m3") ? 0 : 1
        const totalizerFlowLine = chart.addLineSeries({
          color: color,
          lineWidth: '1px',
          priceScaleId: side[count],
          lineStyle: style[lineType],
          lastValueVisible: false,
          priceLineVisible: false,
        })
        totalizerFlowLine.setData(totalizer_flow);
        this.seriesAPI.set("totalizer_flow_flowmeter", totalizerFlowLine)
      }

      if(type == "zmc_flow_"){ 
        const lineType = this.channelsValue.indexOf("zmc_flow")
        const count = ($(".chart-legend-units").find("#left_unit").text() == "m3/h") ? 0 : 1
        const ZMCFlowLine = chart.addLineSeries({
          color: color,
          lineWidth: '1px',
          priceScaleId: side[count],
          lineStyle: style[lineType],
          lastValueVisible: false,
          priceLineVisible: false,
        })
        ZMCFlowLine.setData(zmc_flow);
        this.seriesAPI.set("zmc_flow", ZMCFlowLine)
      }

      if(type == "plc_totalizer_flow_"){ 
        const lineType = this.channelsValue.indexOf("plc_totalizer_flow")
        const count = ($(".chart-legend-units").find("#left_unit").text() == "m3") ? 0 : 1
        const plc_totalizerFlowLine = chart.addLineSeries({
          color: color,
          lineWidth: '1px',
          priceScaleId: side[count],
          lineStyle: style[lineType],
          lastValueVisible: false,
          priceLineVisible: false,
        })
        plc_totalizerFlowLine.setData(plc_totalizer_flow);
        this.seriesAPI.set("plc_totalizer_flow_flowmeter", plc_totalizerFlowLine)
      }
    
    }else{
      chart.removeSeries(chartAPI)
      this.seriesAPI.delete(type + adf_id)
      $("#"+ adf_id.replaceAll(":", "\\:")+".card-sensor").find("."+type.substring(0, type.length-1)).hide()
    }
    if(this.seriesAPI.size == this.seriesData.size * this.channelsValue.length){
      $(".checkbox__label").children().prop("checked", true)
    }
  }

  switchAllSeries(e){
    const chartAPIMap =  this.seriesAPI
    const chart = this.chart
    const data = this.seriesData
    const legend = $(".card-sensor")
    if(chartAPIMap.size < data.size * this.channelsValue.length){
      $(".checkbox__sensors").children().prop("checked", true)
      legend.find(".card-sensor__container").children().show()
      data.forEach(function(value, key, map){
        const array = value
        const element = array["logs"].length > 0 ? JSON.parse(array["logs"]) : []
        const color = array["color"]
        const id = array["adf_id"]
        const data = this.fix_timestamp(element.map(d =>{ return {value: d.pressure ? d.pressure : undefined , time: this.timeToTz(d.created_at)}}));
        const mediumPressure = this.fix_timestamp(element.map((d)=>{ return {value: d.real_setpoint ? d.real_setpoint : undefined, time: this.timeToTz(d.created_at)}}));
        const temperature = this.fix_timestamp(element.map((d)=>{ return {value: d.temperature ? d.temperature : undefined, time: this.timeToTz(d.created_at)}}));
        const battery = this.fix_timestamp(element.map((d)=>{ return {value: d.battery ? d.battery : undefined , time: this.timeToTz(d.created_at)}}));
        const cwj_totalizer = this.fix_timestamp(element.map((d)=>{ return {value: d.cwj_totalizer, time: this.timeToTz(d.created_at)}}));
        const cwj_zmc_flow = this.fix_timestamp(element.map((d)=>{ return {value: d.cwj_zmc_flow, time: this.timeToTz(d.created_at)}}));
        const cwj_clients_sum = this.fix_timestamp(element.map((d)=>{ return {value: d.cwj_clients_sum, time: this.timeToTz(d.created_at)}}));
        const cwj_clients_dif = this.fix_timestamp(element.map((d)=>{ return {value: d.cwj_clients_dif, time: this.timeToTz(d.created_at)}}));
        const totalizer_flow = this.fix_timestamp(element.map((d)=>{ return {value: d.totalizer_flow, time: this.timeToTz(d.created_at)}}));
        const plc_totalizer_flow = this.fix_timestamp(element.map((d)=>{ return {value: d.plc_totalizer_flow, time: this.timeToTz(d.created_at)}}));
        const zmc_flow = this.fix_timestamp(element.map((d)=>{ return {value: d.zmc_flow, time: this.timeToTz(d.created_at)}}));
        const passive = this.fix_timestamp(element.map((d)=>{ return {value: d.passive, time: this.timeWithoutTz(d.created_at)}}));
        const reactive = this.fix_timestamp(element.map((d)=>{ return {value: d.reactive, time: this.timeWithoutTz(d.created_at)}}));
        const autoAlg = this.fix_timestamp(element.map((d)=>{ return {value: d.auto_alg, time: this.timeWithoutTz(d.created_at)}}));

        let side = [  'left', 'right']
        let style = [0, 3]
        if (element[0]){
          if("pressure" in element[0] && chartAPIMap.get("pressure_" + id) == undefined){
            const count = ($(".chart-legend-units").find("#left_unit").text() == "bar") ? 0 : 1
            const pressureLine = chart.addLineSeries({
              color: color,
              lineWidth: '1px',
              priceScaleId: side[count],
              lineStyle: style[count],
              entireTextOnly: "bar",
              lastValueVisible: false,
              priceLineVisible: false,
            });
            pressureLine.setData(data); 
            chartAPIMap.set("pressure_"+ id, pressureLine)
          }
          //Setpoint
          if("real_setpoint" in element[0] && chartAPIMap.get("real_setpoint_" + id) == undefined){ 
            const count = ($(".chart-legend-units").find("#left_unit").text() == "bar") ? 0 : 1
            const setpointLine = chart.addLineSeries({
              color: color,
              lineWidth: '1px',
              priceScaleId: side[count],
              lineStyle: style[count],
              lastValueVisible: false,
              priceLineVisible: false,
            })
            setpointLine.setData(mediumPressure);
            chartAPIMap.set("real_setpoint_"+ id, setpointLine)
          }

          //Passive
          if("passive" in element[0] && chartAPIMap.get("passive_" + id) == undefined){ 
            const count = ($(".chart-legend-units").find("#left_unit").text() == "bar") ? 0 : 1
            const passiveLine = chart.addLineSeries({
              color: color,
              lineWidth: '1px',
              priceScaleId: side[count],
              lineStyle: style[count],
              lastValueVisible: false,
              priceLineVisible: false,
            })
            passiveLine.setData(passive);
            chartAPIMap.set("passive_"+ id, passiveLine)
          }

          //Reactive
          if("reactive" in element[0] && chartAPIMap.get("reactive_" + id) == undefined){ 
            const count = ($(".chart-legend-units").find("#left_unit").text() == "bar") ? 0 : 1
            const reactiveLine = chart.addLineSeries({
              color: color,
              lineWidth: '1px',
              priceScaleId: side[count],
              lineStyle: style[count],
              lastValueVisible: false,
              priceLineVisible: false,
            })
            reactiveLine.setData(reactive);
            chartAPIMap.set("reactive_"+ id, reactiveLine)
          }

          //Reactive
          if("autoAlg" in element[0] && chartAPIMap.get("autoAlg_" + id) == undefined){ 
            const count = ($(".chart-legend-units").find("#left_unit").text() == "bar") ? 0 : 1
            const autoAlgLine = chart.addLineSeries({
              color: color,
              lineWidth: '1px',
              priceScaleId: side[count],
              lineStyle: style[count],
              lastValueVisible: false,
              priceLineVisible: false,
            })
            autoAlgLine.setData(autoAlg);
            chartAPIMap.set("autoAlg_"+ id, autoAlgLine)
          }
        
          //Temperature
          if("temperature" in element[0] && chartAPIMap.get("temperature_" + id) == undefined){ 
            const count = ($(".chart-legend-units").find("#left_unit").text() == "ºC") ? 0 : 1
            const temperatureLine = chart.addLineSeries({
              color: color,
              lineWidth: '1px',
              priceScaleId: side[count],
              lineStyle: style[count],
              lastValueVisible: false,
              priceLineVisible: false,
            })
            temperatureLine.setData(temperature);
            chartAPIMap.set("temperature_"+ id, temperatureLine)
          }      
      
          //Battery
          if("battery" in element[0] && chartAPIMap.get("battery_" + id) == undefined){ 
            const count = ($(".chart-legend-units").find("#left_unit").text() == "%") ? 0 : 1
            const batteryLine = chart.addLineSeries({
              color: color,
              lineWidth: '1px',
              priceScaleId: side[count],
              lineStyle: style[count],
              lastValueVisible: false,
              priceLineVisible: false,
            })
            batteryLine.setData(battery);
            chartAPIMap.set("battery_"+ id, batteryLine)
          }
          
          //Flow
          if("cwj_totalizer" in element[0] && chartAPIMap.get("cwj_toltalizer_flowmeter") == undefined){ 
            const count = ($(".chart-legend-units").find("#left_unit").text() == "m3") ? 0 : 1
            const totalizerLine = chart.addLineSeries({
              color: color,
              lineWidth: '1px',
              priceScaleId: side[count],
              lineStyle: style[count],
              lastValueVisible: false,
              priceLineVisible: false,
            });
            totalizerLine.setData(cwj_totalizer);
            this.seriesAPI.set("cwj_totalizer_flowmeter", totalizerLine)
          }
          if("cwj_zmc_flow" in element[0] && chartAPIMap.get("cwj_zmc_flow_flowmeter") == undefined){
            const lineType = this.channelsValue.indexOf("cwj_zmc_flow")
            const count = ($(".chart-legend-units").find("#left_unit").text() == "m3/h") ? 0 : 1
            $("#"+ side[count]+"_unit").text("m3/h")
            const zmcFlowLine = chart.addLineSeries({
              color: color,
              lineWidth: '1px',
              priceScaleId: side[count],
              lineStyle: style[lineType],
              lastValueVisible: false,
              priceLineVisible: false,
            });
            zmcFlowLine.setData(cwj_zmc_flow);
            this.seriesAPI.set("cwj_zmc_flow_flowmeter", zmcFlowLine)
          }

          if("cwj_clients_sum" in element[0] && chartAPIMap.get("cwj_clients_sum_flowmeter") == undefined){
            const lineType = this.channelsValue.indexOf("cwj_clients_sum")
            const count = ($(".chart-legend-units").find("#left_unit").text() == "m3/h") ? 0 : 1
            $("#"+ side[count]+"_unit").text("m3/h")
            const clientsSumLine = chart.addLineSeries({
              color: color,
              lineWidth: '1px',
              priceScaleId: side[count],
              lineStyle: style[lineType],
              lastValueVisible: false,
              priceLineVisible: false,
            });
            clientsSumLine.setData(cwj_clients_sum);
            this.seriesAPI.set("cwj_clients_sum_flowmeter", clientsSumLine)
          }

          if("cwj_clients_dif" in element[0] && chartAPIMap.get("cwj_clients_dif_flowmeter") == undefined){
            const lineType = this.channelsValue.indexOf("cwj_clients_dif")
            const count = ($(".chart-legend-units").find("#left_unit").text() == "m3/h") ? 0 : 1
            $("#"+ side[count]+"_unit").text("m3/h")
            const clientsDifLine = chart.addLineSeries({
              color: color,
              lineWidth: '1px',
              priceScaleId: side[count],
              lineStyle: style[lineType],
              lastValueVisible: false,
              priceLineVisible: false,
            });
            clientsDifLine.setData(cwj_clients_dif);
            this.seriesAPI.set("cwj_clients_dif_flowmeter", clientsDifLine)
          }

          if("totalizer_flow" in element[0] && chartAPIMap.get("totalizer_flow") == undefined){
            const lineType = this.channelsValue.indexOf("totalizer_flow")
            const count = ($(".chart-legend-units").find("#left_unit").text() == "m3") ? 0 : 1
            $("#"+ side[count]+"_unit").text("m3")
            const totalizerFlowLine = chart.addLineSeries({
              color: color,
              lineWidth: '1px',
              priceScaleId: side[count],
              lineStyle: style[lineType],
              lastValueVisible: false,
              priceLineVisible: false,
            });
            totalizerFlowLine.setData(totalizer_flow);
            this.seriesAPI.set("totalizer_flow_flowmeter", totalizerFlowLine)
          }

          if("zmc_flow" in element[0] && chartAPIMap.get("zmc_flow") == undefined){
            const lineType = this.channelsValue.indexOf("zmc_flow")
            const count = ($(".chart-legend-units").find("#left_unit").text() == "m3/h") ? 0 : 1
            $("#"+ side[count]+"_unit").text("m3/h")
            const calculatedInstantFlowLine = chart.addLineSeries({
              color: color,
              lineWidth: '1px',
              priceScaleId: side[count],
              lineStyle: style[lineType],
              lastValueVisible: false,
              priceLineVisible: false,
            });
            calculatedInstantFlowLine.setData(zmc_flow);
            this.seriesAPI.set("zmc_flow_flowmeter", calculatedInstantFlowLine)
          }

          if("plc_totalizer_flow" in element[0] && chartAPIMap.get("plc_totalizer_flow") == undefined){
            const lineType = this.channelsValue.indexOf("plc_totalizer_flow")
            const count = ($(".chart-legend-units").find("#left_unit").text() == "m3") ? 0 : 1
            $("#"+ side[count]+"_unit").text("m3")
            const plc_totalizerFlowLine = chart.addLineSeries({
              color: color,
              lineWidth: '1px',
              priceScaleId: side[count],
              lineStyle: style[lineType],
              lastValueVisible: false,
              priceLineVisible: false,
            });
            plc_totalizerFlowLine.setData(plc_totalizer_flow);
            this.seriesAPI.set("plc_totalizer_flow_flowmeter", plc_totalizerFlowLine)
          }
        }
    }.bind(this))
    }else{
      $(".checkbox__sensors").children().prop("checked", false)
      legend.find(".card-sensor__container").children().hide()
      chartAPIMap.forEach(function(value, key, map){
        chart.removeSeries(value)
        chartAPIMap.delete(key)
      })
    }
  }
  rand(frm, to) {
    return ~~(Math.random() * (to - frm)) + frm;
  }

  // you could use this function to convert all your times to required time zone
  timeToTz(originalTime) {
    var date = new Date(originalTime)
    var offset = date.getTimezoneOffset()
    const zonedDate = new Date(date.setUTCHours(date.getUTCHours() + offset/-60));
    return Math.round(zonedDate.getTime() / 1000);  
  }
  timeWithoutTz(originalTime){
    var date = new Date(originalTime)
    return date.getTime() / 1000  ;
  }

  fix_timestamp(array){
    let uniqueDataArray = [];
    let seenDataTimestamps = new Set();
    
    array.forEach((obj) => {
      if (!seenDataTimestamps.has(obj.time)) {
        uniqueDataArray.push(obj);
        seenDataTimestamps.add(obj.time);
      }
    });
    uniqueDataArray.sort((a, b) => {
      if (a.time < b.time) {
        return -1;
      }
      if (a.time > b.time) {
        return 1;
      }
      return 0;
    });
    return uniqueDataArray
  }
}