import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["button", "input", "edit", "close"]

  show(){
    this.editTarget.style.display = "none"
    this.inputTarget.disabled = false
    this.closeTarget.style.display = "flex"
    this.buttonTarget.style.display = "block"
  }

  cancel(){
    this.editTarget.style.display = "flex"
    this.inputTarget.disabled = true
    this.closeTarget.style.display = "none"
    this.buttonTarget.style.display = "none"
  }

}