import { Controller } from "stimulus";
import $ from "jquery";
import moment from "moment";
import daterangepicker from "daterangepicker";
import Cookies from 'js-cookie'
import i18n from '../../initializers/i18n'



export default class extends Controller {
  static targets = ["submitButton", "element"]
  connect() {
    
    $(this.elementTarget).daterangepicker({
      autoApply: true,
      minDate: this.minDate,
      startDate: this.startDate,
      endDate: this.endDate,
      autoApply: true,
      linkedCalendars: false,
      opens: "center",
      locale: {
        format: "DD/MM/YYYY",
        daysOfWeek:  i18n.t("date.abbr_day_names"),
        monthNames:  i18n.t("date.month_names"),
      },
    });
    
    $(this.element).on('apply.daterangepicker', function(ev, picker) {
      if($(this.element).attr('id') == "single-selector"){
        this.submitForm(ev)
      }else if(this.elementTarget.dataset.onchange == "true"){
        $(this.elementTarget).closest("form").trigger('submit.rails');
      }
      //$(this.elementTarget).closest("form").
    }.bind(this));
  }

  show(event){
    event.preventDefault();
    $(this.elementTarget).click()
  }
  
  submitForm(ev){
    Rails.handleRemote.call($(this.elementTarget).closest("form")[0], ev);
  }
  get minDate() {
    const minDate = this.elementTarget.dataset.minDate;
    return minDate ? new Date(minDate) : false;
  }
  get startDate() {
    const startDate = this.elementTarget.dataset.startDate;
    return startDate ? moment(startDate, "DD-MM-YYYY") : moment();
  }
  get endDate() {
    const endDate = this.elementTarget.dataset.endDate;
    return endDate ? moment(endDate, "DD-MM-YYYY") : moment();
  }
  
}
