import "jquery/src/jquery.js"

$(document).on("turbo:load", () => {
    if ($("#valve-index").lenght !== 0) {
        $(".datagrid.zmc_pressure_table_grid").addClass("pressure-table")
        $(".datagrid.zmc_details_table_grid").addClass("details-table")
    }

    $("#opc_create_params").on("submit", (e) => {
      $("#opc_create_params_button").hide()
      $("#opc_create_params_loading").show()
  });
})