import { Controller } from 'stimulus'
import Cookies from 'js-cookie'


export default class extends Controller {

    connect() {
      if(Cookies.get('sidebar') == "true"){
        this.open()
      }else{
        this.close()
      }

      $('.sidebar-filter__button-container').click(function () {
        this.open_or_close()
      }.bind(this));
      // $('.sidebar-filter__wrap').click(function () {
      //   this.open_or_close()
      // }.bind(this));
    }
    

    disconnect() {
    }

    open_or_close(){
      if(Cookies.get('sidebar') == "true"){
        this.close()
      }else{
        this.open()
      }
    }

    open(){
      $('.sidebar-filter__container').addClass('showMenu');
      //$('.sidebar-filter__wrap').addClass('sidebar-filter__wrapper');
      $("#sidebar-filter__button").removeClass('fa-angle-left');
      $("#sidebar-filter__button").addClass('fa-angle-right');
      $(".sidebar-filter__menu-filters").show()
      $(".sidebar-filter__menu-filters-closed").hide()
      if($("#open_channel_0").find(".string").text() == ""){
        $("#open_channel_0").hide()
      }
      if($("#open_channel_1").find(".string").text() == ""){
        $("#open_channel_1").hide()
      }
      Cookies.set('sidebar', true);
    }

    close(){
      $('.sidebar-filter__container').removeClass('showMenu');
      //$('.sidebar-filter__wrap').removeClass('sidebar-filter__wrapper');
      $("#sidebar-filter__button").removeClass('fa-angle-right');
      $("#sidebar-filter__button").addClass('fa-angle-left');
      $(".sidebar-filter__menu-filters").hide()
      $(".sidebar-filter__menu-filters-closed").show()
      if($("#open_channel_0").find(".string").text() == ""){
        $("#closed_channel_0").hide()
      }
      if($("#open_channel_1").find(".string").text() == ""){
        $("#closed_channel_1").hide()
      }
      Cookies.set('sidebar', false);
    }
}