import { Controller } from 'stimulus'
import $ from "jquery"
import i18n from '../../initializers/i18n'



export default class extends Controller {

  connect() {
    let SensorsConnection = typeof sensors_connection !== 'undefined' ? true : false;
    let translated_all = i18n.t("views.common.all_sensors")
    $(this.element).select2({
      closeOnSelect: this.closeOnSelect,
      placeholder: this.placeholder,
      allowHTML: true,
      allowClear: this.allowClear,
      multiple: this.multiple,
      sorter: function (data) {
        if (this.selected.length > 0) {
          let removedElements = []
          let finalData = []
          data.forEach(element => {
            if (this.selected.includes(element.id)) {
              removedElements.push(element)
            } else {
              finalData.push(element)
            }
          })
          finalData.sort()
          removedElements.sort()
          finalData.unshift(...removedElements)
          return finalData
        } else {
          return data.sort();
        }
      }.bind(this)
      
    })
    $(".select2-selection__choice").hide()
    $('.select2-selection__arrow').html('<i class="fa fa-angle-down"></i>');

    if (SensorsConnection) {
      var style = document.createElement('style');
      style.setAttribute('type', 'text/css');
      style.innerText = ".select2-results__option:before {content: none;} .select2-results__option[aria-selected=true]:before {content: none;} ";
      style.setAttribute('id', 'sensors_connection__option');
      document.head.appendChild(style)
    } else {
      $("#sensors_connection__option").remove();
    }

    
    
    if ($("#sensors-index").length !== 0 && this.multiple) {
      if (this.selected.length == 0) {
        $(".select2-search__field").css('width', '100%');
      } else if (this.selected.length == 1) {
        $(this).find(".select2-search__field").css('width', '1rem');
        $(this.element).parent().find(".select2-selection__rendered").first().append(`<span id="length" class="select2__placeholder">${this.selected.length} ${i18n.t("views.common.selected.one")} </span>`)
      } else if (this.selected.length > 1) {
        $(this).find(".select2-search__field").css('width', '1rem');
        if (this.selected.indexOf(-1) != -1){
          $(this.element).parent().find(".select2-selection__rendered").first().append(`<span id="length" class="select2__placeholder">${this.selected.length - 1} ${i18n.t("views.common.selected.multiple")} </span>`)
        }else{
          $(this.element).parent().find(".select2-selection__rendered").first().append(`<span id="length" class="select2__placeholder">${this.selected.length} ${i18n.t("views.common.selected.multiple")} </span>`)
        }
      }
      $(this.element).parent().find(".select2-container").first().on("click", () => {
        if ($(this.element).parent().find("#length").first()) {
          //$(this.element).parent().find("#length").first().hide()
        }
      })
      if ($("#sensor_").length !== 0){
        let previous_all_selected = $(`#sensor_ option:contains(${translated_all})`).prop("selected") || false
        $(this.element).change(() => {
          if($(this.element).prop("name") == "sensor[]"){
            if(previous_all_selected) {
              if(!$(`#sensor_ option:contains(${translated_all})`).prop("selected")){
                $("#sensor_ > option").prop("selected","")
                previous_all_selected = false
              }else{
                if($("#sensor_ > option:selected").length != $("#sensor_ > option").length){
                  $(`#sensor_ option:contains(${translated_all})`).prop("selected", false)
                  previous_all_selected = false
                }
              }
            }else{
              if(!$(`#sensor_ option:contains(${translated_all})`).prop("selected")){
                if($("#sensor_ > option:selected").length == $("#sensor_ > option").length - 1){
                  $(`#sensor_ option:contains(${translated_all})`).prop("selected", "selected")
                  previous_all_selected = true
                }
              }else{
                $("#sensor_ > option").prop("selected","selected")
                previous_all_selected = true
              }
            }
          }
          const length = $(this.element).find(':selected').length
          if (length == 0) {
            $(this.element).parent().find(".select2-selection__rendered").first().append(`<span id="length" class="select2__placeholder"> ${i18n.t("views.common.selected.placeholder")} </span>`)
            //$(".select2-search__field").css('width', '100%');
          } else if (length == 1) {
            $(this).find(".select2-search__field").css('width', '1rem');
            $(this.element).parent().find(".select2-selection__rendered").first().append(`<span id="length" class="select2__placeholder">${length} ${i18n.t("views.common.selected.one")} </span>`)
          } else if (length > 1) {
            $(this).find(".select2-search__field").css('width', '1rem');
            if ($(`#sensor_ option:contains(${translated_all})`).prop("selected") && $(this.element).prop("name") == "sensor[]"){
              $(this.element).parent().find(".select2-selection__rendered").first().append(`<span id="length" class="select2__placeholder">${length - 1} ${i18n.t("views.common.selected.multiple")} </span>`)
            }else{
              $(this.element).parent().find(".select2-selection__rendered").first().append(`<span id="length" class="select2__placeholder">${length} ${i18n.t("views.common.selected.multiple")} </span>`)
            }
          }
          $(".select2-selection__choice").hide()
        })
      }
    }
  }

  disconnect() {
    $(this.element).select2('destroy')
  }
  get placeholder() {
    return this.element.dataset.placeholder || "Selecione"
  }
  //js dataset turns letter caps to not caps
  get allowClear() {
    return this.element.dataset.allowclear == "true"
  }
  get multiple() {
    return this.element.dataset.multiple == "multiple"
  }
  get closeOnSelect() {
    return this.element.dataset.closeonselect == "true"
  }
  get selected() {
    if (this.element.dataset.selected) {
      return JSON.parse(this.element.dataset.selected)
    } else {
      return []
    }
  }
}