import { Controller } from 'stimulus'
import Cookies from 'js-cookie'

export default class extends Controller {
  static targets = ["modal"];

  connect(){
  }

  disconnect(){

  }
  open(event){
    event.preventDefault();
    this.modalTarget.showModal();
    this.modalTarget.addEventListener('click', (e) => this.backdropClick(e));
    if($("#valve-index").length != 0){
      //console.log(Cookies.get("modal"))
      if(Cookies.get('modal') == "instant"){
        this.showInstant()
      }else if(Cookies.get('modal') == "periodic"){
        this.showPeriod()
      } else {
        this.showAutomatic()
      }
    }

  }

  close(event){
    event.preventDefault();
    this.modalTarget.close();
    $("#opc_create_params")[0].reset()
    $(this.modalTarget).find(".range").each( function(){
      let $range = this
      const val = $range.value;
      const min = $range.min ? $range.min : 0;
      const max = $range.max ? $range.max : 100;
      $range.style.backgroundSize = ((val - min) * 100) / (max - min) + "% 100%";
    })
  }
  
  delete(e){
    this.modalTarget.close();
  }
  submit(e){
    //this.modalTarget.close();
  }

  backdropClick(event){
    event.target === this.modalTarget && this.close(event)
  }
  showPeriod() {
    $('#period').show();
    $('#instant').hide();
    $('#automatic').hide()
    $('#adjusted_valve_params_log_status').get(0).value = "periodic"
  }

  showInstant  () {
    $('#period').hide();
    $('#instant').show();
    $('#automatic').hide()
    $('#adjusted_valve_params_log_status').get(0).value = "instant"
  }

  showAutomatic  () {
    $('#period').hide();
    $('#instant').hide();
    $('#automatic').show()
    $('#adjusted_valve_params_log_status').get(0).value = "automatic"
  }

}