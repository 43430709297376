$(document).on("turbo:load", () => {
  $("#togglePassword").on("click", () => {
    const type = $("#password").attr("type") === "password" ? "text" : "password";
    $("#password").attr("type", type);
    $("#togglePassword").toggleClass("fa-eye fa-eye-slash");
  });

  $("#togglePasswordConfirmation").on("click", () => {
    const type = $("#user_password_confirmation").attr("type") === "password" ? "text" : "password";
    $("#user_password_confirmation").attr("type", type);
    $("#togglePasswordConfirmation").toggleClass("fa-eye fa-eye-slash");
  });
  $("#toggleCurrentPassword").on("click", () => {
    const type = $("#user_current_password").attr("type") === "password" ? "text" : "password";
    $("#user_current_password").attr("type", type);
    $("#toggleCurrentPassword").toggleClass("fa-eye fa-eye-slash");
  });
  $("#toggleNewPassword").on("click", () => {
    const type = $("#user_password").attr("type") === "password" ? "text" : "password";
    $("#user_password").attr("type", type);
    $("#toggleNewPassword").toggleClass("fa-eye fa-eye-slash");
  });
})

