import consumer from "./consumer"
import Cookies from 'js-cookie'

document.addEventListener('turbo:load', () => {
  if($("#valve-index").length != 0){
    const user_id = Cookies.get('user_id')
    // console.log(user_id)
    consumer.subscriptions.create({channel: "LogReceivedChannel", user_id: user_id},
    {
      connected() {
        // Called when the subscription is ready for use on the server
        // console.log("Connected to log received channel " + user_id);
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
        // console.log("Disconnected from log received channel")
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel
        const event = new CustomEvent("received-log")
        window.dispatchEvent(event)
      }
    });

  }
})

