import { Controller } from 'stimulus'
export default class extends Controller {
    static targets = ["input"]

    connect() {
    }

    disconnect() {
    }

    updateToggle() {
        $(this.inputTarget).is(':checked') ? $(this.inputTarget).parent().addClass("checkbox--active") : $(this.inputTarget).parent().removeClass("checkbox--active");
    }
}