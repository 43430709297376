import "jquery/src/jquery.js"

$(document).on("turbo:load", () => {
    if( $("#devise-sessions-new").lenght !== 0) {
        $("#new_user").on("submit", () => {
            $("#action_button").html("<span class='loader'></span>");
        });

        $("#email").on("click", () => {
            $("#input-form-feedback-error-email").removeClass("fa-solid fa-triangle-exclamation input-form__feedback--error");
        });

        $("#password").on("click", () => {
            $("#input-form-feedback-error-password").removeClass("fa-solid fa-triangle-exclamation input-form__feedback--error");
            $("#input-form-feedback-error-password").addClass("fa-regular fa-eye input-form__feedback--icon");
            $("#input-form-feedback-error-password").attr('id', "togglePassword");

            $("#togglePassword").on("click", () => {
                const type = $("#password").attr("type") === "password" ? "text" : "password";
                $("#password").attr("type", type);
                $("#togglePassword").toggleClass("fa-eye fa-eye-slash");
              });
        });

        $("#password2").on("click", () => {
            $("#input-form-feedback-error-password").removeClass("fa-solid fa-triangle-exclamation input-form__feedback--error");
            $("#input-form-feedback-error-password").addClass("fa-regular fa-eye input-form__feedback--icon");
            $("#input-form-feedback-error-password").attr('id', "togglePassword2");

            $("#togglePassword2").on("click", () => {
                const type = $("#password2").attr("type") === "password" ? "text" : "password";
                $("#password2").attr("type", type);
                $("#togglePassword2").toggleClass("fa-eye fa-eye-slash");
              });
        });
    }
})