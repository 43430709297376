import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["range", "inputNumber"]

  connect(){
    this.setRangeValue()
  }

  disconnect(){

  }

  setInputValue(){
    const $range = this.rangeTarget
    const $input = this.inputNumberTarget

    

    const val = $range.value;
    const min = $range.min ? $range.min : 0;
    const max = $range.max ? $range.max : 100;
    $input.value = val;
    $range.style.backgroundSize = ((val - min) * 100) / (max - min) + "% 100%";

  }

  setRangeValue(){
    const $range = this.rangeTarget
    const $input = this.inputNumberTarget

    const val = $input.value;
    const max = $range.max ? $range.max : 100;
    const min = $range.min ? $range.min : 0;
    if (val == "") {
      val = 0;
    }
    $range.value = val;
    $range.style.backgroundSize = ((val - min) * 100) / (max - min) + "% 100%";
    
    if(val < 0){
      $range.style.backgroundSize = "0% 100%";
    }
  }
  
  

}