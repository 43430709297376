import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    $('.header-notification').on('click', (e) => this.closeOnClick(e));
    $(function() {
      setTimeout(function(){
        $('.header-notification').slideUp(500);
      }, 5000);
    });

  }
  closeOnClick(event){
    $('.header-notification').slideUp(500);
  }
}
