import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["checkbox", "input"]

  connect(){

  }

  disconnect(){

  }

  modalOpen(){
    this.inputTargets.forEach((element) => {
      if(element.getAttribute("checkname") == $("#current_mode").get(0).innerHTML){
        $(element).prop("checked", true)
        $(element).closest('div').addClass("border-blue-base");

      } else{
          $(element).prop("checked", false)
          $(element).closest('div').removeClass("border-blue-base");
        }
    })
  }

  isChecked() {
    this.inputTargets.forEach((element) => {
      if(element.checked) {
        $(element).closest('div').addClass("border-blue-base");
      }
      else {
        $(element).closest('div').removeClass("border-blue-base");
      }
    });
  }  
}