import { Controller } from 'stimulus'
import Cookies from 'js-cookie'

export default class extends Controller {
  static targets = ["button", "loader"]

  connect() {
  }

  download () {
    const name = this.element.dataset.name
    Cookies.set(name, false)
    const $button = $(this.buttonTarget)
    const $loader = $(this.loaderTarget)
    $button.css('display', 'none')
    $loader.fadeIn()

    const interval = setInterval(function () {
      const download = Cookies.get(name)
      if (download == 'true') {
        $loader.css('display', 'none')
        $button.fadeIn()
        clearInterval(interval)
      }
    }, 1000)

  }
}