import { Controller } from 'stimulus'

export default class extends Controller {

    connect() {

        $('.filter-dropdown__menu').hide();

        $(this.element).find(".filter-dropdown__title").click(function () {
            let menu = $(this).parent().find('.filter-dropdown__menu');
            let button = $(this).find('#filter-dropdown__button');
            if (button.hasClass('fa-angle-down')) {
                button.addClass('fa-angle-up')
                button.removeClass('fa-angle-down')
                menu.show();
            } else {
                button.addClass('fa-angle-down')
                button.removeClass('fa-angle-up')
                menu.hide();
            }
        });
    }

    disconnect() {
    }
}