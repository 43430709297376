import { Controller } from 'stimulus'
import Cookies from 'js-cookie'

export default class extends Controller {
  static targets = ["graph", "table"]

    connect() {
      if(Cookies.get('table') == "true"){
        this.showTable()
      }else{
        this.showGraph()
      }
    }

    disconnect() {
    }

    showTable() {
      $(".datagrid, zmc_pressure_table_grid, pressure-table").show()
      let valveGraph = typeof container_valve !== 'undefined' ? true : false
      if(valveGraph){
        $(".valve-layout-grid__cards-and-table").children(".data-table").children(".pagination").show()
      }else{
        $(".pagination").show()
      }
      setTimeout(() => {
        $("#graph").css("display", "none")
      }, 150)
      this.graphTarget.classList.remove("active")
      this.tableTarget.classList.add("active")
      Cookies.set('table', true);
    }

    showGraph() {
      const event = new CustomEvent("reload-chart")
      window.dispatchEvent(event)
      $(".datagrid, zmc_pressure_table_grid, pressure-table").hide()
      let valveGraph = typeof container_valve !== 'undefined' ? true : false
      if(valveGraph){
        $(".valve-layout-grid__cards-and-table").children(".data-table").children(".pagination").hide()
      }else{
        $(".pagination").hide()
      }
      $("#toggle").show()
      $("#graph").css("display", "block")
      this.tableTarget.classList.remove("active")
      this.graphTarget.classList.add("active")
      Cookies.set('table', false);
      // new ResizeSensor(document.querySelector('.chart'), event => {
      //   chart.resize(document.querySelector('.chart').offsetHeight, document.querySelector('.chart').offsetWidth)
      // })
    }
}