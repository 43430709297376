import { Controller } from 'stimulus'
import 'leaflet'
import '../../packs/leaflet-providers'
import { latLng } from 'leaflet'
import i18n from '../../initializers/i18n'


export default class extends Controller {
  static targets = ["leaflet"]
  static values = {
    sensors: Array,
    valve: Array,
    valveUrl: String,
    sensorUrl: String,
    scale: Object
  }

  connect(){
    const scale = this.scaleValue
    const sensor_url = this.sensorUrlValue;
    const valve_url = this.valveUrlValue;
    const sensorsData = this.sensorsValue;
    const valveData = this.valveValue;
    var map = L.map('leaflet').setView([40.15651, -8.85598], 16);

    // L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    // center:[0,0],
    // zoom:19,
    // minZoom: 15,    
    // maxZoom: 20,
    //     attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    //     //className: 'map-tiles'
    // }).addTo(map);

    L.tileLayer.provider('MapBox', {
        attribution: '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        //tileSize: 512,
        minZoom: 15,    
        maxZoom: 20,
        //zoomOffset: -1,
        id: 'mapbox/dark-v10',
        accessToken: 'pk.eyJ1IjoibG9vcHNlbnNlIiwiYSI6ImNsbjIzNzFjaTA1MmYyaW5vang5cjg5bmwifQ.pw_BprsSdNVvoxBCH-SBOg',
    }).addTo(map);

    var tooltip = L.control({position: 'bottomright'});
    
    function setColorByPressure(pressure){
      if(pressure == null){
        return ['gray', 'no-pressure'];
      }else{
        if(pressure < scale["LOW_PRESSURE"]){
          return ['#FFCF41', 'low-pressure'] ;
        }
        else if(pressure >= scale["LOW_PRESSURE"] && pressure < scale["NORMAL_PRESSURE_LOW"]){
          return ['#3E64EA', 'normal-pressure-low']; 
        }else if(pressure >= scale["NORMAL_PRESSURE_LOW"] && pressure < scale["NORMAL_PRESSURE"]){
          return ['#133BCD', 'normal-pressure']; 
        }
        else if(pressure >= scale["NORMAL_PRESSURE"] && pressure < scale["NORMAL_PRESSURE_HIGH"]){
          return ['#09258A', 'normal-pressure-high'];
        }
        else if(pressure >= scale["NORMAL_PRESSURE_HIGH"] && pressure < scale["HIGH_PRESSURE"]){
          return ['#09258A', 'normal-pressure-high'];
        }
        else if(pressure >= scale["HIGH_PRESSURE"]){
          return ['#F63F3F', 'high-pressure']; 
        }
      }
    }

    function setDataCard(element){
      var battery_color;
      if (element.battery == null){
        battery_color="default"
      }else if (element.battery > 0){
        if (element.battery >= 31){ 
          battery_color="green"
        }
        else if (element.battery >= 16){
          battery_color="yellow"
        }
        else{
          battery_color="red"
        }
      }
      
      tooltip.onAdd = function(map) {
        
        var div = L.DomUtil.create('div', 'tooltip-infos');
        div.style.borderStyle = "none"
        div.style.background = "linear-gradient(white 31%, black 31% 100%)"
        if (element.pressure == undefined){
          div.innerHTML += 
          '<div class="tooltip-col2 pb-6">'+
            '<div class="tooltip-sensors__icon-border-no-pressure">'+
              '<i class="fa-regular fa-sensor tooltip-sensors__icon-no-pressure"></i>'+
            '</div>'+
            '<div class"">'+
              '<span class="tooltip-header-title tooltip-header-title--default">'+element.name+'</span><br>'+
              '<span class="tooltip-header-subtitle">ID: '+element.adf_id+'</span>'+
            '</div>'+
            '<div class="">'+
              `<span class="tooltip-header-title tooltip-header-title--default">-- ${ i18n.t("views.common.pressure.measure") }</span><br>`+
            '</div>'+
          '</div>';
        }else{
          div.innerHTML += 
          '<div class="tooltip-col2 pb-6">'+
            `<div class="tooltip-sensors__icon-border-${setColorByPressure(element.pressure)[1]}">`+
              `<i class="fa-regular fa-sensor tooltip-sensors__icon-${setColorByPressure(element.pressure)[1]}"></i>`+
            '</div>'+
            '<div class"">'+
              '<span class="tooltip-header-title tooltip-header-title--default">'+element.name+'</span><br>'+
              '<span class="tooltip-header-subtitle">ID: '+element.adf_id+'</span>'+
            '</div>'+
            '<div class="">'+
              `<div class="info-card__pressure-container info-card__pressure-container--${setColorByPressure(element.pressure)[1]}">`+
                `<p class="info-card__pressure-text--measure info-card__pressure-text--${setColorByPressure(element.pressure)[1]}">${ i18n.t("views.common.pressure.title")}</p>`+
                `<h4 class="info-card__pressure-text info-card__pressure-text--${setColorByPressure(element.pressure)[1]}">${element.pressure.toFixed(2)} <span class="info-card__pressure-text--measure">${ i18n.t("views.common.pressure.measure")}</span></h4>`+
                '<span class="info-card__location"></span>'+
              '</div>'+
            '</div>'+
          '</div>';
        }


        if (battery_color==="default"){
          div.innerHTML +=
          '<div class="tooltip-col2">'+
            '<div class="">'+
              `<span class="tooltip-title">${ i18n.t("views.common.temperature.title") }</span><br>`+
              '<div class="tooltip-battery-background">'+
              `<span class="tooltip-battery-text tooltip-battery-text--${battery_color}"> -- ${ i18n.t("views.common.temperature.measure") }</span><br>`+
              '</div>'+
              '</div>'+
            '<div class="">'+
              `<span class="tooltip-title">${ i18n.t("views.common.battery.title") }</span><br>`+
              `<div class="tooltip-battery-background tooltip-battery-background--${battery_color}">`+
                `<span class="tooltip-battery-text tooltip-battery-text--${battery_color}"> --${ i18n.t("views.common.battery.measure") }</span>`+
              '</div>'+
            '</div>'+
          '</div>';
        }else{
          div.innerHTML +=
          '<div class="tooltip-col2">'+
            '<div class="">'+
              `<span class="tooltip-title">${ i18n.t("views.common.temperature.title") }</span><br>`+
              '<div class="tooltip-battery-background">'+
              '<span class="tooltip-battery-text tooltip-battery-text--default">'+element.temperature+`${ i18n.t("views.common.temperature.measure") }</span><br>`+
              '</div>'+
              '</div>'+
            '<div class="">'+
              `<span class="tooltip-title">${ i18n.t("views.common.battery.title") }</span><br>`+
              `<div class="tooltip-battery-background tooltip-battery-background--${battery_color}">`+
                `<span class="tooltip-battery-text tooltip-battery-text--${battery_color}">`+element.battery+`${ i18n.t("views.common.battery.measure") }</span>`+
              '</div>'+
            '</div>'+
          '</div>';
        }
        
        div.innerHTML +=
        '<div class="tooltip-address" >'+
          `<span class="tooltip-title">${ i18n.t("views.common.address") }</span><br>`+
          '<span class="tooltip-text">'+element.address+'</span><br>'+
        '</div>';

        div.innerHTML +=
        '<div class="tooltip-col2">'+
          '<div class="">'+
            `<span class="tooltip-title">${ i18n.t("views.common.lat") }</span><br>`+
            '<span class="tooltip-text">'+element.latitude+'</span><br>'+
          '</div>'+
          '<div class="">'+
          `<span class="tooltip-title">${ i18n.t("views.common.long") }</span><br>`+
          '<span class="tooltip-text">'+element.longitude+'</span><br>'+
          '</div>'+
          '<div class="">'+
            `<span class="tooltip-title">${ i18n.t("views.common.elevation") }</span><br>`+
            '<span class="tooltip-text">'+element.elevation+'m</span><br>'+
          '</div>'+
        '</div>';

        return div;
      };
    }

    function setValveDataCard(element){

      
      tooltip.onAdd = function(map) {
        
        var div = L.DomUtil.create('div', 'tooltip-infos');
        div.style.borderStyle = "none"
        div.style.background = "linear-gradient(white 31%, black 31% 100%)"
        if(element.pressure == null){
          div.innerHTML += 
          '<div class="tooltip-col2 pb-6">'+
          '<div class="tooltip-sensors__icon-border-default">'+
              '<i class="fa-solid fa-pipe-valve tooltip-sensors__icon-default"></i>'+
            '</div>'+
            '<div class"">'+
              '<span class="tooltip-header-title tooltip-header-title--default">'+element.name+'</span><br>'+
              '<span class="tooltip-header-subtitle">ID: '+element.adf_id+'</span>'+
            '</div>'+
            '<div class="">'+
              `<span class="tooltip-header-title tooltip-header-title--default">--${ i18n.t("views.common.pressure.measure") }</span><br>`+
            '</div>'+
          '</div>';
        }else{
          div.innerHTML += 
          '<div class="tooltip-col2 pb-6">'+
          `<div class="tooltip-sensors__icon-border-${setColorByPressure(element.pressure)[1]}">`+
              `<i class="fa-solid fa-pipe-valve tooltip-sensors__icon-${setColorByPressure(element.pressure)[1]}"></i>`+
            '</div>'+
            '<div class"">'+
              '<span class="tooltip-header-title tooltip-header-title--default">'+element.name+'</span><br>'+
              '<span class="tooltip-header-subtitle">ID: '+element.adf_id+'</span>'+
            '</div>'+
            '<div class="">'+
              `<div class="info-card__pressure-container info-card__pressure-container--${setColorByPressure(element.pressure)[1]}"> `+
                `<p class="info-card__pressure-text--measure info-card__pressure-text--${setColorByPressure(element.pressure)[1]}">${ i18n.t("views.common.pressure.title") }</p>` +
                `<h4 class="info-card__pressure-text info-card__pressure-text--${setColorByPressure(element.pressure)[1]}">${element.pressure.toFixed(2)}<span class="info-card__pressure-text--measure">${ i18n.t("views.common.pressure.measure") }</span></h4>`+
              '</div>'+
            '</div>'+
          '</div>';
        }
      if(element.command_mode == null){
        div.innerHTML +=
        '<div class="tooltip-col2">'+
          '<div class="">'+
            `<span class="tooltip-title">${ i18n.t("views.common.adjusted_pressure.title") }</span><br>`+
            '<div class="tooltip-battery-background">'+
             '<span class="tooltip-battery-text tooltip-battery-text--default">--</span><br>'+
            '</div>'+
          '</div>'+
          '<div class="">'+
            `<span class="tooltip-title">${ i18n.t("views.valve.mode.title") }</span><br>`+
            '<div class="tooltip-battery-background tooltip-battery-background--default">'+
              `<span class="tooltip-battery-text tooltip-battery-text--default">${ i18n.t("views.valve.mode.instant") }</span>`+
            '</div>'+
          '</div>'+
        '</div>'; 
      }else if(element.command_mode){
        div.innerHTML +=
        '<div class="tooltip-col2">'+
          '<div class="">'+
           `<span class="tooltip-title">${ i18n.t("views.common.adjusted_pressure.title") }</span><br>`+
            '<div class="tooltip-battery-background">'+
            '<span class="tooltip-battery-text tooltip-battery-text--default">'+element.set_point.toFixed(2)+'</span><br>'+
            '</div>'+
          '</div>'+
          '<div class="">'+
            `<span class="tooltip-title">${ i18n.t("views.valve.mode.title") }</span><br>`+
            '<div class="tooltip-battery-background tooltip-battery-background--default">'+
            `<span class="tooltip-battery-text tooltip-battery-text--default">${ i18n.t("views.valve.mode.instant") }</span>`+
            '</div>'+
          '</div>'+
        '</div>'; 
      }else{
        div.innerHTML +=
        '<div class="tooltip-col2">'+
          '<div class="">'+
           `<span class="tooltip-title">${ i18n.t("views.common.adjusted_pressure.title") }</span><br>`+
            '<div class="tooltip-battery-background">'+
            '<span class="tooltip-battery-text tooltip-battery-text--default">'+element.set_point.toFixed(2)+'</span><br>'+
            '</div>'+
          '</div>'+
          '<div class="">'+
          `<span class="tooltip-title">${ i18n.t("views.valve.mode.title") }</span><br>`+
            '<div class="tooltip-battery-background tooltip-battery-background--default">'+
              `<span class="tooltip-battery-text tooltip-battery-text--default">${ i18n.t("views.valve.mode.period") }</span>`+
            '</div>'+
          '</div>'+
        '</div>'; 
      }
        div.innerHTML +=
        '<div class="tooltip-address">'+
          `<span class="tooltip-title">${ i18n.t("views.common.address") }</span><br>`+
          '<span class="tooltip-text">'+element.address+'</span><br>'+
        '</div>';

        div.innerHTML +=
        '<div class="tooltip-col2">'+
          '<div class="">'+
          `<span class="tooltip-title">${ i18n.t("views.common.lat") }</span><br>`+
            '<span class="tooltip-text">'+element.latitude+'</span><br>'+
          '</div>'+
          '<div class="">'+
          `<span class="tooltip-title">${ i18n.t("views.common.long") }</span><br>`+
          '<span class="tooltip-text">'+element.longitude+'</span><br>'+
          '</div>'+
        '</div>';

        return div;
      };
    }


    for(let i=0; i<sensorsData.length; i++) {
      for(let j=0; j<sensorsData[i].connections.length; j++){
        var today = new Date();
        var now = today.getTime();
        
        var dateS1 = new Date(sensorsData[i].created_at)
        var created_atS1 = dateS1.getTime();
        var diferenceS1 = now - created_atS1;
       
        
        if(sensorsData[i].connections[j][0]==sensorsData[j].latitude){
          if(sensorsData[i].connections[j][1]==sensorsData[j].longitude){
            var dateS2 = new Date(sensorsData[j].created_at)
            var created_atS2 = dateS2.getTime();
            var diferenceS2 = now - created_atS2;
          }
        }
        
        var hotlineLayer = L.hotline([
          [
            sensorsData[i].latitude, 
            sensorsData[i].longitude,
            0.0
          ],
          [
            sensorsData[i].connections[j][0], 
            sensorsData[i].connections[j][1],
            1.0 //pressão do sensor destino
          ]], {
            min: 0.0,
            max: 1.0,
            palette: {
              0.0: setColorByPressure(sensorsData[i].pressure, diferenceS1)[0],
              1.0: setColorByPressure(sensorsData[i].connections[j][2], diferenceS2)[0],
            },
            weight: 5,
            outlineColor: '#000000',
            outlineWidth: 0
          }).addTo(map);
      }
    }

    for(let i=0; i<valveData.length; i++) {
      for(let j=0; j<valveData[i].connections.length; j++){
        var dateV = new Date(valveData[i].created_at)
        var created_atV = dateV.getTime();
        
        var today = new Date();
        var now = today.getTime();
        
        var diferenceV = now - created_atV;
        
        for(let k=0; k<sensorsData.length; k++){
          if(valveData[i].connections[j][0]==sensorsData[k].latitude){
            if(valveData[i].connections[j][1]==sensorsData[k].longitude){
              var dateS = new Date(sensorsData[k].created_at)
              var created_atS = dateS.getTime();
              var diferenceS = now - created_atS;
            }
          }
        }
      var hotlineLayer = L.hotline([
        [
          valveData[i].latitude, 
          valveData[i].longitude,
          0.0
        ],
        [
          valveData[i].connections[j][0], 
          valveData[i].connections[j][1],
          1.0
        ]], {
          min: 0.0,
          max: 1.0,
          palette: {
            0.0: setColorByPressure(valveData[i].pressure, diferenceS)[0],
            1.0: setColorByPressure(valveData[i].connections[j][2], diferenceV)[0],
          },
          weight: 5,
          outlineColor: '#000000',
          outlineWidth: 0
        }).addTo(map);
      }
    }
    
    
    for(let i=0; i<sensorsData.length; i++) {
      
      var date = new Date(sensorsData[i].created_at)
      var created_at = date.getTime();
      
      var today = new Date();
      var now = today.getTime();
      
      var diference = now - created_at;
      
      var sensorOp = L.circleMarker([sensorsData[i].latitude, sensorsData[i].longitude], {
        color: 'transparent',
        fillColor: setColorByPressure(sensorsData[i].pressure, diference)[0],
        fillOpacity: 0.3,
        radius: 20,
      }).addTo(map);
      var sensor = L.circleMarker([sensorsData[i].latitude, sensorsData[i].longitude], {
        color: 'transparent',
        fillColor: setColorByPressure(sensorsData[i].pressure, diference)[0],
        fillOpacity: 1,
        radius: 12,
      }).addTo(map);

      const fontAwesomeIcon = L.divIcon({
        html: '<span class="fa-regular fa-sensor map__sensor-icon"></span>',
        iconAnchor: [22, 17],
        className: 'map__sensor-icon'
      });
      
      var marker = L.marker([sensorsData[i].latitude, sensorsData[i].longitude], {
          icon: fontAwesomeIcon
        }).addTo(map);
      sensorOp.bindTooltip("<div class='map__tooltip-text'><b>" + (sensorsData[i].pressure != null ? sensorsData[i].pressure.toFixed(2) : "--") + " bar</b></div>", 
      {
          direction: 'right',
          permanent: true,
          //sticky: true,
          offset: [10, 0],
          opacity: 1,
          className: 'leaflet-tooltip' 
      });
        sensor.on('click', function (e){
          window.location.href = sensor_url;
        })
        sensorOp.on('click', function (e){
          window.location.href = sensor_url;
        })
        marker.on('click', function (e){
          window.location.href = sensor_url;
        })

        sensor.on('mouseover', function (e) {
          setDataCard(sensorsData[i]);
          tooltip.addTo(map);    
        });
        sensorOp.on('mouseover', function (e) {
          setDataCard(sensorsData[i]);
          tooltip.addTo(map);    
        });
        marker.on('mouseover', function (e) {
          setDataCard(sensorsData[i]);
          tooltip.addTo(map);    
        });
        
        sensorOp.on('mouseout', function () {
          tooltip.remove();
        });
        sensor.on('mouseout', function () {
          tooltip.remove();
        });
        marker.on('mouseout', function () {
          tooltip.remove();
        });
        

    }

    for(let i=0; i<valveData.length; i++) {
     
      var date = new Date(valveData[i].created_at)
      var created_at =date.getTime();
      var today = new Date();
      var now = today.getTime();
      var diference = now - created_at;

      var valveOp = L.circleMarker([valveData[i].latitude, valveData[i].longitude], {
        color: 'transparent',
        fillColor: setColorByPressure(valveData[i].pressure, diference)[0],
        fillOpacity: 0.3,
        radius: 20,
        
      }).addTo(map);
      var valve = L.circleMarker([valveData[i].latitude, valveData[i].longitude], {
        color: 'transparent',
        fillColor: setColorByPressure(valveData[i].pressure, diference)[0],
        fillOpacity: 1,
        radius: 12
        
      }).addTo(map);

      const fontAwesomeIcon = L.divIcon({
        html: '<i class="fa-solid fa-pipe-valve map__valve-icon"></i>',
        iconAnchor: [16, 14],
        className: 'myDivIcon'
      });
      
      var marker = L.marker([valveData[i].latitude, valveData[i].longitude], {
          icon: fontAwesomeIcon
        }).addTo(map);
      valveOp.bindTooltip("<div class='map__tooltip-text'><b>" + (valveData[i].pressure != null ? valveData[i].pressure.toFixed(2) : "--") + " bar</b></div>", 
      {
          direction: 'right',
          permanent: true,
          sticky: false,
          offset: [10, 0],
          opacity: 1,
          className: 'leaflet-tooltip' 
      });
        valve.on('click', function (e) {
          window.location.href = valve_url;
        });
        valveOp.on('click', function (e) {
          window.location.href = valve_url;
        });
        marker.on('click', function (e) {
          window.location.href = valve_url;
        });

        valve.on('mouseover', function (e) {
          setValveDataCard(valveData[i]);
          tooltip.addTo(map);    
        });
        valveOp.on('mouseover', function (e) {
          setValveDataCard(valveData[i]);
          tooltip.addTo(map);    
        });
        marker.on('mouseover', function (e) {
          setValveDataCard(valveData[i]);
          tooltip.addTo(map);    
        });

        valve.on('mouseout', function () {
            tooltip.remove();
        });
        valveOp.on('mouseout', function () {
          tooltip.remove();
        });
        marker.on('mouseout', function () {
          tooltip.remove();
        });
  
    }

    var legend = L.control({position: 'topright'});

    legend.onAdd = function(map) {
      
      var div = L.DomUtil.create('div', 'legend');
      div.innerHTML += '<div class"legend-color"><span class="legend-color"></span></div>';
      div.innerHTML +=
      '<div class=" flex flex-col -mt-[4px]">'+
      `<span class="legend-text">> ${scale["HIGH_PRESSURE"]} bar</span>`+
      `<span class="legend-text">  ${scale["NORMAL_PRESSURE_HIGH"]} bar</span>`+
      `<span class="legend-text">  ${scale["NORMAL_PRESSURE"]} bar</span>`+
      `<span class="legend-text">  ${scale["NORMAL_PRESSURE_LOW"]} bar</span>`+
      `<span class="legend-text">< ${scale["LOW_PRESSURE"]} bar</span>`+
      '</div>';
      return div;
    };
    legend.addTo(map);
    
  }

  hideTooltips(){
    $(".leaflet-tooltip").hide();
    
  }

  showTooltips(){
    $(".leaflet-tooltip").show();
    
  }


  handleTooltips(e){
    //console.log(e.srcElement.checked);
    if(e.srcElement.checked === false){
      //console.log("hide")
      this.hideTooltips();
    }else{
      //console.log("show")
      this.showTooltips();
    }
  }

  disconnect(){

  }
  

}