document.addEventListener("turbo:load", () => {

  $(".max-input-value").on("keyup",((e) => {
    let $this = $(this);
    $this.each(function(index){
      let val = $(this).val();
      if (val > 0 && val < 10) {
        e.preventDefault();
        $this.val(String(val).padStart((2-val.length),'0'));
      }
    }
    )
  }))
  // set max value when inserted number greater than max value
  $(".max-input-value").focusout(function (e) {
    var $this = $(this);
    var val = $this.val();
    var max = $this.attr("max");
    var min = $this.attr("min");

    if (max > 0 && val > max) {
      e.preventDefault();
      $this.val(max);
    } else if (val < 0) {
      e.preventDefault();
      $this.val("");
    }
  });

  $(".valuerange").focusout(function (e) {
    var $this = $(this);
    var val = Number($this.val()).toFixed(2);
    var max = Number($this.attr("max"));

    if (max > 0 && val > max) {
      e.preventDefault();
      $this.val(max);
      +val;
    } else if (val < 0) {
      e.preventDefault();
      $this.val(0);
    }
  });
});
