import { Controller } from 'stimulus'
import Cookies from 'js-cookie'

export default class extends Controller {
  static targets = ["spinner"]

  connect(){
    $(this.spinnerTarget).hide()
  }

  disconnect(){

  }
  leave(event){
    if(Cookies.get("download") != "false"){
      if(Cookies.get('sidebar') == "true"){
        $(".sidebar-filter__button-container").click()
      }
      //console.log("Here")
      $(this.spinnerTarget).show()
    }
  }
}