import { Controller } from 'stimulus'
import 'leaflet'
import '../../packs/leaflet-timeline-slider'
import '../../packs/leaflet-providers'
import { latLng } from 'leaflet'
import moment from 'moment/moment'
import i18n from '../../initializers/i18n'


export default class extends Controller {
  static targets = ["leaflet"]
  static values = {
    sensors: Object,
    valve: Object,
    time: Array,
    scale: Object
  }

  

  connect(){
    const scale = this.scaleValue
    i18n.locale = $('body').data('locale')
    const dates = this.timeValue.map((d) => { return  [new Date(d.time).toISOString()]})
    var map = L.map('leaflet').setView([40.15651, -8.85598], 16);
    var group = L.layerGroup();  

    // L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    // center:[0,0],
    // zoom:19,
    // minZoom: 15,    
    // maxZoom: 20,
    //     attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    //     //className: 'map-tiles'
    // }).addTo(map);

    
    var legend = L.control({position: 'topright'});

    legend.onAdd = function(map) {
      
      var div = L.DomUtil.create('div', 'legend');
      div.innerHTML += '<div class"legend-color"><span class="legend-color"></span></div>';
      div.innerHTML +=
      '<div class=" flex flex-col -mt-[4px]">'+
      `<span class="legend-text">> ${scale["HIGH_PRESSURE"]} bar</span>`+
      `<span class="legend-text">  ${scale["NORMAL_PRESSURE_HIGH"]} bar</span>`+
      `<span class="legend-text">  ${scale["NORMAL_PRESSURE"]} bar</span>`+
      `<span class="legend-text">  ${scale["NORMAL_PRESSURE_LOW"]} bar</span>`+
      `<span class="legend-text">< ${scale["LOW_PRESSURE"]} bar</span>`+
      '</div>';
      return div;
    };
    legend.addTo(map);
    
    L.tileLayer.provider('MapBox', {
        attribution: '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        //tileSize: 512,
        minZoom: 15,    
        maxZoom: 20,
        //zoomOffset: -1,
        id: 'mapbox/dark-v10',
        accessToken: 'pk.eyJ1IjoibG9vcHNlbnNlIiwiYSI6ImNsbjIzNzFjaTA1MmYyaW5vang5cjg5bmwifQ.pw_BprsSdNVvoxBCH-SBOg',
    }).addTo(map);

    let sensorsData = this.sensorsValue.info
    let sensorsLogsData = JSON.parse(this.sensorsValue.logs)
    let valveData = this.valveValue.info
    let valveLogsData = JSON.parse(this.valveValue.logs)

    let getAndUpdateMap = function({label, value, map, exclamation}){
      let date = moment(label, "YYYY-MM-DDThh:mm:sssZ").toDate()
   
      let temp_group = L.layerGroup();   

        var tooltip = L.control({position: 'bottomright'});
          
        function setColorByPressure(pressure){
          if (pressure != undefined && typeof pressure === 'object'){
            pressure = pressure.pressure
          }
          if(pressure == null || pressure == undefined){
            return ['gray', 'no-pressure'];
          }else{
            if(pressure < scale["LOW_PRESSURE"]){
              return ['#FFCF41', 'low-pressure'] ;
            }
            else if(pressure >= scale["LOW_PRESSURE"] && pressure < scale["NORMAL_PRESSURE_LOW"]){
              return ['#3E64EA', 'normal-pressure-low']; 
            }else if(pressure >= scale["NORMAL_PRESSURE_LOW"] && pressure < scale["NORMAL_PRESSURE"]){
              return ['#133BCD', 'normal-pressure']; 
            }
            else if(pressure >= scale["NORMAL_PRESSURE"] && pressure < scale["NORMAL_PRESSURE_HIGH"]){
              return ['#09258A', 'normal-pressure-high'];
            }
            else if(pressure >= scale["NORMAL_PRESSURE_HIGH"] && pressure < scale["HIGH_PRESSURE"]){
              return ['#09258A', 'normal-pressure-high'];
            }
            else if(pressure >= scale["HIGH_PRESSURE"]){
              return ['#F63F3F', 'high-pressure']; 
            }
          }
        }

          function setDataCard(log, info){
            var battery_color;
            if (log != undefined){
              if (log.battery >= 31){ 
                battery_color="green"
              }
              else if (log.battery >= 16){
                battery_color="yellow"
              }
              else{
                battery_color="red"
              }
            }else{
              battery_color="grey"
            }
            
            tooltip.onAdd = function(map) {
              var div = L.DomUtil.create('div', 'tooltip-infos');
              div.style.borderStyle = "none"
              div.style.background = "linear-gradient(white 31%, black 31% 100%)"
              if (log == undefined){
                div.innerHTML += 
                '<div class="tooltip-col2 pb-6">'+
                  '<div class="tooltip-sensors__icon-border-no-pressure">'+
                    '<i class="fa-regular fa-sensor tooltip-sensors__icon-no-pressure"></i>'+
                  '</div>'+
                  '<div class"">'+
                    '<span class="tooltip-header-title tooltip-header-title--default">'+info.name+'</span><br>'+
                    '<span class="tooltip-header-subtitle">ID: '+info.adf_id+'</span>'+
                  '</div>'+
                  '<div class="">'+
                    `<span class="tooltip-header-title tooltip-header-title--default">-- ${ i18n.t("views.common.pressure.measure") }</span><br>`+
                  '</div>'+
                '</div>';
              }else{
                div.innerHTML += 
                '<div class="tooltip-col2 pb-6">'+
                `<div class="tooltip-sensors__icon-border-${setColorByPressure(log.pressure)[1]}">`+
                  `<i class="fa-regular fa-sensor tooltip-sensors__icon-${setColorByPressure(log.pressure)[1]}"></i>`+
                '</div>'+
                '<div class"">'+
                  '<span class="tooltip-header-title tooltip-header-title--default">'+ info.name+'</span><br>'+
                  '<span class="tooltip-header-subtitle">ID: '+ log.adf_id+'</span>'+
                '</div>'+
                '<div class="">'+
                  `<div class="info-card__pressure-container info-card__pressure-container--${setColorByPressure(log.pressure)[1]}">`+
                    `<p class="info-card__pressure-text--measure info-card__pressure-text--${setColorByPressure(log.pressure)[1]}">${ i18n.t("views.common.pressure.title")}</p>`+
                    `<h4 class="info-card__pressure-text info-card__pressure-text--${setColorByPressure(log.pressure)[1]}">${log.pressure.toFixed(2)} <span class="info-card__pressure-text--measure">${ i18n.t("views.common.pressure.measure")}</span></h4>`+
                    '<span class="info-card__location"></span>'+
                  '</div>'+
                '</div>'+
              '</div>';
              }
      
        
            if(battery_color==="green"){
                div.innerHTML +=
                '<div class="tooltip-col2">'+
                  '<div class="">'+
                  `<span class="tooltip-title">${ i18n.t("views.common.temperature.title") }</span><br>`+
                  '<div class="tooltip-battery-background">'+
                    '<span class="tooltip-battery-text tooltip-battery-text--default">'+log.temperature+` ${ i18n.t("views.common.temperature.measure") }</span><br>`+
                    '</div>'+
                    '</div>'+
                  '<div class="">'+
                    `<span class="tooltip-title">${ i18n.t("views.common.battery.title") }</span><br>`+
                    '<div class="tooltip-battery-background tooltip-battery-background--green">'+
                      '<span class="tooltip-battery-text tooltip-battery-text--green">'+log.battery+` ${ i18n.t("views.common.battery.measure") }</span>`+
                    '</div>'+
                  '</div>'+
                '</div>';
              }
              else if(battery_color==="yellow"){
                div.innerHTML +=
                '<div class="tooltip-col2">'+
                  '<div class="">'+
                  `<span class="tooltip-title">${ i18n.t("views.common.temperature.title") }</span><br>`+
                  '<div class="tooltip-battery-background">'+
                  '<span class="tooltip-battery-text tooltip-battery-text--default">'+log.temperature+` ${ i18n.t("views.common.temperature.measure") }</span><br>`+
                  '</div>'+
                    '</div>'+
                  '<div class="">'+
                    `<span class="tooltip-title">${ i18n.t("views.common.battery.title") }</span><br>`+
                    '<div class="tooltip-battery-background tooltip-battery-background--yellow">'+
                    '<span class="tooltip-battery-text tooltip-battery-text--yellow">'+log.battery+` ${ i18n.t("views.common.battery.measure") }</span>`+
                    '</div>'+
                  '</div>'+
                '</div>';

              }else if(battery_color ==="red"){
                  div.innerHTML +=
                  '<div class="tooltip-col2">'+
                    '<div class="">'+
                      `<span class="tooltip-title">${ i18n.t("views.common.temperature.title") }</span><br>`+
                      '<div class="tooltip-battery-background">'+
                      '<span class="tooltip-battery-text tooltip-battery-text--default">'+log.temperature+` ${ i18n.t("views.common.temperature.measure") }</span><br>`+
                      '</div>'+
                      '</div>'+
                    '<div class="">'+
                      `<span class="tooltip-title">${ i18n.t("views.common.battery.title") }</span><br>`+
                      '<div class="tooltip-battery-background tooltip-battery-background--red">'+
                      '<span class="tooltip-battery-text tooltip-battery-text--red">'+log.battery+` ${ i18n.t("views.common.battery.measure") }</span>`+
                      '</div>'+
                    '</div>'+
                  '</div>';
              } else{
                div.innerHTML +=
                  '<div class="tooltip-col2">'+
                    '<div class="">'+
                    `<span class="tooltip-title">${ i18n.t("views.common.temperature.title") }</span><br>`+
                    '<div class="tooltip-battery-background">'+
                    '<span class="tooltip-battery-text tooltip-battery-text--default">-- '+`${ i18n.t("views.common.temperature.measure") }</span><br>`+
                      '</div>'+
                      '</div>'+
                    '<div class="">'+
                      `<span class="tooltip-title">${ i18n.t("views.common.battery.title") }</span><br>`+
                      '<div class="tooltip-battery-background tooltip-battery-background--default">'+
                      '<span class="tooltip-battery-text tooltip-battery-text--default">-- '+`${ i18n.t("views.common.battery.measure") }</span>`+
                      '</div>'+
                    '</div>'+
                  '</div>';
              } 
              
              div.innerHTML +=
              '<div class="tooltip-address" >'+
                `<span class="tooltip-title">${ i18n.t("views.common.address") }</span><br>`+
                '<span class="tooltip-text">'+info.address+'</span><br>'+
              '</div>';
              
              div.innerHTML +=
              '<div class="tooltip-col2">'+
                '<div class="">'+
                  `<span class="tooltip-title">${ i18n.t("views.common.lat") }</span><br>`+
                  '<span class="tooltip-text">'+info.latitude+'</span><br>'+
                '</div>'+
                '<div class="">'+
                `<span class="tooltip-title">${ i18n.t("views.common.long") }</span><br>`+
                '<span class="tooltip-text">'+info.longitude+'</span><br>'+
                '</div>'+
                '<div class="">'+
                  `<span class="tooltip-title">${ i18n.t("views.common.elevation") }</span><br>`+
                  '<span class="tooltip-text">'+info.elevation+'m</span><br>'+
                '</div>'+
              '</div>';

              return div;
            };
          }

          function setValveDataCard(log, info){
            const str = info.address;

            const address = str.match(/.{1,30}/g) ?? [];
            
            tooltip.onAdd = function(map) {
              
              var div = L.DomUtil.create('div', 'tooltip-infos');
              div.style.borderStyle = "none"
              div.style.background = "linear-gradient(white 31%, black 31% 100%)"
              if(log == undefined){
                div.innerHTML += 
                '<div class="tooltip-col2 pb-6">'+
                '<div class="tooltip-sensors__icon-border-default">'+
                  '<i class="fa-regular fa-sensor tooltip-sensors__icon-no-pressure"></i>'+
                  '</div>'+
                  '<div class"">'+
                    '<span class="tooltip-header-title tooltip-header-title--default">'+info.name+'</span><br>'+
                    '<span class="tooltip-header-subtitle">ID: '+info.adf_id+'</span>'+
                  '</div>'+
                  '<div class="">'+
                    '<span class="tooltip-header-title tooltip-header-title--default">'+(log != undefined ? element.pressure.toFixed(2) : "--" )+` ${ i18n.t("views.common.pressure.measure") }</span><br>`+
                  '</div>'+
                '</div>';
              }else {
                div.innerHTML += 
                '<div class="tooltip-col2 pb-6">'+
                `<div class="tooltip-sensors__icon-border-${setColorByPressure(log.pressure)[1]}">`+
                    `<i class="fa-solid fa-pipe-valve tooltip-sensors__icon-${setColorByPressure(log.pressure)[1]}"></i>`+
                  '</div>'+
                  '<div class"">'+
                    '<span class="tooltip-header-title tooltip-header-title--default">'+info.name+'</span><br>'+
                    '<span class="tooltip-header-subtitle">ID: '+info.adf_id+'</span>'+
                  '</div>'+
                  '<div class="">'+
                    `<div class="info-card__pressure-container info-card__pressure-container--${setColorByPressure(log.pressure)[1]}"> `+
                      `<p class="info-card__pressure-text--measure info-card__pressure-text--${setColorByPressure(log.pressure)[1]}">${ i18n.t("views.common.pressure.title") }</p>` +
                      `<h4 class="info-card__pressure-text info-card__pressure-text--${setColorByPressure(log.pressure)[1]}">${log.pressure.toFixed(2)}<span class="info-card__pressure-text--measure">${ i18n.t("views.common.pressure.measure") }</span></h4>`+
                    '</div>'+
                  '</div>'+
                '</div>';  
              }
              if( log != undefined){
                if(log.command_mode){
                  div.innerHTML +=
                  '<div class="tooltip-col2">'+
                    '<div class="">'+
                    `<span class="tooltip-title">${ i18n.t("views.common.adjusted_pressure.title") }</span><br>`+
                      '<div class="tooltip-battery-background">'+
                      '<span class="tooltip-battery-text tooltip-battery-text--default">'+(log.pressure != null ? log.pressure.toFixed(2) : "--" )+'</span><br>'+
                      '</div>'+
                    '</div>'+
                    '<div class="">'+
                    `<span class="tooltip-title">${ i18n.t("views.valve.mode.title") }</span><br>`+
                      '<div class="tooltip-battery-background tooltip-battery-background--default">'+
                        `<span class="tooltip-battery-text tooltip-battery-text--default">${ i18n.t("views.valve.mode.instant") }</span>`+
                      '</div>'+
                    '</div>'+
                  '</div>'; 
                }else{
                  div.innerHTML +=
                  '<div class="tooltip-col2">'+
                    '<div class="">'+
                      `<span class="tooltip-title">${ i18n.t("views.common.adjusted_pressure.title") }</span><br>`+
                      '<div class="tooltip-battery-background">'+
                      '<span class="tooltip-battery-text tooltip-battery-text--default">'+(log.pressure != null ? log.pressure.toFixed(2) : "--" )+'</span><br>'+
                      '</div>'+
                    '</div>'+
                    '<div class="">'+
                      `<span class="tooltip-title">${ i18n.t("views.valve.mode.title") }</span><br>`+
                      '<div class="tooltip-battery-background tooltip-battery-background--default">'+
                      `<span class="tooltip-battery-text tooltip-battery-text--default">${ i18n.t("views.valve.mode.instant") }</span>`+
                      '</div>'+
                    '</div>'+
                  '</div>'; 
                }
              }else{
                div.innerHTML +=
                  '<div class="tooltip-col2">'+
                    '<div class="">'+
                      `<span class="tooltip-title">${ i18n.t("views.common.adjusted_pressure.title") }</span><br>`+
                      '<div class="tooltip-battery-background">'+
                      '<span class="tooltip-battery-text tooltip-battery-text--default">'+ "--" +'</span><br>'+
                      '</div>'+
                    '</div>'+
                    '<div class="">'+
                      `<span class="tooltip-title">${ i18n.t("views.valve.mode.title") }</span><br>`+
                      '<div class="tooltip-battery-background tooltip-battery-background--default">'+
                        `<span class="tooltip-battery-text tooltip-battery-text--default">${ i18n.t("views.valve.mode.period") }</span>`+
                      '</div>'+
                    '</div>'+
                  '</div>'; 
              }
              div.innerHTML +=
              '<div class="tooltip-address">'+
                `<span class="tooltip-title">${ i18n.t("views.common.address") }</span><br>`+
                '<span class="tooltip-text">'+info.address+'</span><br>'+
              '</div>';

              div.innerHTML +=
              '<div class="tooltip-col2">'+
                '<div class="">'+
                  `<span class="tooltip-title">${ i18n.t("views.common.lat") }</span><br>`+
                  '<span class="tooltip-text">'+info.latitude+'</span><br>'+
                '</div>'+
                '<div class="">'+
                `<span class="tooltip-title">${ i18n.t("views.common.long") }</span><br>`+
                '<span class="tooltip-text">'+info.longitude+'</span><br>'+
                '</div>'+
              '</div>';

              return div;
            };
          }

          for(let i=0; i<sensorsData.length; i++) {
            let last_log = sensorsLogsData.find( obj => {return (obj.sensor_id === sensorsData[i].id && new Date(obj.created_at) >  (date - (60*60*1000)) && new Date(obj.created_at) <  date )}) 
            for(let j=0; j<sensorsData[i].connections.length; j++){
              let last_connection_log = sensorsLogsData.find( obj => {return (obj.sensor_id === sensorsData[i].connections[j].id&& new Date(obj.created_at) >  (date - (60*60*1000)) && new Date(obj.created_at) <  date )}) 

              var hotlineLayer = L.hotline([
                [
                  sensorsData[i].latitude, 
                  sensorsData[i].longitude,
                  0.0
                ],
                [
                  sensorsData[i].connections[j].lat,  
                  sensorsData[i].connections[j].long,
                  1.0 //pressão do sensor destino
                ]], {
                  min: 0.0,
                  max: 1.0,
                  palette: {
                    0.0: setColorByPressure(last_log)[0],
                    1.0: setColorByPressure(last_connection_log)[0], 
                  },
                  weight: 5,
                  outlineColor: '#000000',
                  outlineWidth: 0
                });
                temp_group.addLayer(hotlineLayer);
            }
          }

          for(let i=0; i<valveData.length; i++) {
            let last_log = valveLogsData.find( obj => {return (obj.valve_id === valveData[i].id && new Date(obj.created_at) >  (date - (60*60*1000)) && new Date(obj.created_at) <  date )}) 
            for(let j=0; j<valveData[i].connections.length; j++){
              let last_connection_log = sensorsLogsData.find( obj => {return (obj.sensor_id === valveData[i].connections[j].id && new Date(obj.created_at) >  (date - (60*60*1000)) && new Date(obj.created_at) <  date )}) 
              var hotlineLayer = L.hotline([
                [
                  valveData[i].latitude, 
                  valveData[i].longitude,
                  0.0
                ],
                [
                  valveData[i].connections[j].lat, 
                  valveData[i].connections[j].long,
                  1.0
                ]], {
                  min: 0.0,
                  max: 1.0,
                  palette: {
                    0.0: setColorByPressure(last_log)[0],
                    1.0: setColorByPressure(last_connection_log)[0],
                  },
                  weight: 5,
                  outlineColor: '#000000',
                  outlineWidth: 0
              });
              temp_group.addLayer(hotlineLayer);
            }
          }
          
          for(let i=0; i<sensorsData.length; i++) {
            let last_log = sensorsLogsData.find( obj => {return (obj.sensor_id === sensorsData[i].id && new Date(obj.created_at) >  (date - (60*60*1000)) && new Date(obj.created_at) <  date )}) 
            //console.log(last_log)
            var sensorOp = L.circleMarker([sensorsData[i].latitude, sensorsData[i].longitude], {
              color: 'transparent',
              fillColor: setColorByPressure(last_log)[0],
              fillOpacity: 0.3,
              radius: 20,
            });
            temp_group.addLayer(sensorOp);
            var sensor = L.circleMarker([sensorsData[i].latitude, sensorsData[i].longitude], {
              color: 'transparent',
              fillColor: setColorByPressure(last_log)[0],
              fillOpacity: 1,
              radius: 12,
            });
            temp_group.addLayer(sensor);

            const fontAwesomeIcon = L.divIcon({
              html: '<span class="fa-regular fa-sensor map__sensor-icon"></span>',
              iconAnchor: [22, 17],
              className: 'map__sensor-icon'
            });
            
            var marker = L.marker([sensorsData[i].latitude, sensorsData[i].longitude], {
                icon: fontAwesomeIcon
              });
            temp_group.addLayer(marker);
            sensorOp.bindTooltip("<div class='map__tooltip-text'><b>" + (last_log != undefined ? last_log.pressure.toFixed(2) : "--") + `${ i18n.t("views.common.pressure.measure") }</b></div>`, 
            {
              direction: 'right',
              permanent: true,
              //sticky: true,
              offset: [10, 0],
              opacity: 1,
              className: 'leaflet-tooltip' 
            });
            
              sensor.on('mouseover', function (e) {
                setDataCard(last_log, sensorsData[i]);
                tooltip.addTo(map);    
              });
              sensorOp.on('mouseover', function (e) {
                setDataCard(last_log, sensorsData[i]);
                tooltip.addTo(map);    
              });
              marker.on('mouseover', function (e) {
                setDataCard(last_log, sensorsData[i]);
                tooltip.addTo(map);    
              });
              
              
              sensorOp.on('mouseout', function () {
                tooltip.remove();
              });
              sensor.on('mouseout', function () {
                tooltip.remove();
              });
              marker.on('mouseout', function () {
                tooltip.remove();
              });
              

          }

          for(let i=0; i<valveData.length; i++) {
            let last_log = valveLogsData.find( obj => {return (obj.valve_id === valveData[i].id && new Date(obj.created_at) >  (date - (60*60*1000)) && new Date(obj.created_at) <  date )}) 
            var valveOp = L.circleMarker([valveData[i].latitude, valveData[i].longitude], {
              color: 'transparent',
              fillColor: setColorByPressure(last_log)[0],
              fillOpacity: 0.3,
              radius: 20,
              
            });
            temp_group.addLayer(valveOp);
            var valve = L.circleMarker([valveData[i].latitude, valveData[i].longitude], {
              color: 'transparent',
              fillColor: setColorByPressure(last_log)[0],
              fillOpacity: 1,
              radius: 12
              
            });
            temp_group.addLayer(valve);

            const fontAwesomeIcon = L.divIcon({
              html: '<i class="fa-solid fa-pipe-valve map__valve-icon"></i>',
              iconAnchor: [16, 14],
              className: 'myDivIcon'
            });
            
            var marker = L.marker([valveData[i].latitude, valveData[i].longitude], {
                icon: fontAwesomeIcon
              });
            temp_group.addLayer(marker);
            
            valveOp.bindTooltip("<div class='map__tooltip-text'><b>" + (last_log != undefined ? last_log.pressure.toFixed(2) : "--") + `${ i18n.t("views.common.pressure.measure") }</b></div>`, 
            {
                direction: 'right',
                permanent: true,
                sticky: false,
                offset: [10, 0],
                opacity: 1,
                className: 'leaflet-tooltip' 
            });
           

              valve.on('mouseover', function (e) {
                setValveDataCard(last_log, valveData[i]);
                tooltip.addTo(map);    
              });
              valveOp.on('mouseover', function (e) {
                setValveDataCard(last_log, valveData[i]);
                tooltip.addTo(map);    
              });
              marker.on('mouseover', function (e) {
                setValveDataCard(last_log, valveData[i]);
                tooltip.addTo(map);    
              });

              valve.on('mouseout', function () {
                  tooltip.remove();
              });
              valveOp.on('mouseout', function () {
                tooltip.remove();
              });
              marker.on('mouseout', function () {
                tooltip.remove();
              });
        
          }  
          map.removeLayer(group);
          group.clearLayers();
          group = temp_group;
          group.addTo(map);
          if($("#default-toggle-size").prop("checked") == false){
            $(".leaflet-tooltip").hide();
            $(".leaflet-tooltip-id").hide(); 
          }   

          
    };

    // L.control.timelineSlider({
    //   timelineItems:dates,
    //   changeMap: getAndUpdateMap,
    //   position: "bottomleft",
    //   // betweenLabelAndRangeSpace: "0px",
    //   bottomBgPadding: "10px",
    //   labelWidth: (($("#map").width()-60)/ dates.length)
    //  })
    // .addTo(map);
    // 

    var num_logs;
    var dates_tam = dates.length;
    var length;
    var slice_start;
    var slice_end;
    if(dates_tam <= 288 && dates_tam>0){
      num_logs=12;
      length=25;
      slice_start=13;
      slice_end=18;
    }else{
      num_logs=288;
      length=dates_tam/288+1;
      slice_start=7;
      slice_end=12;
    }
    let date_tranl = i18n.t("date.abbr_month_names");

    let label_dates=[];
    //console.log(length)
    var dia, mes;    

    for(let j=0; j<length; j++){
      label_dates[j]=dates[(j)*num_logs];
      
      if(label_dates[j]===undefined){
        label_dates[j]=[""];
      }else{
        //console.log(JSON.stringify(label_dates[j]))
        //console.log(JSON.stringify(label_dates[j]).slice(slice_start,slice_end))
        label_dates[j]=[JSON.stringify(label_dates[j]).slice(slice_start,slice_end)] 
    
        if(slice_end === 12){
          dia=label_dates[j][0][3]+label_dates[j][0][4];
          mes=label_dates[j][0][0]+label_dates[j][0][1];
          label_dates[j]=[dia+' '+date_tranl[parseInt(mes)]]
        }
      }
      
    }


    L.control.timelineSlider({
      timelineItems: dates,
      position: "bottomleft",
      changeMap: getAndUpdateMap,
      //extraChangeMapParams: {label_dates},
      bottomBgPadding: "10px",
      labelWidth: (($("#map").width()-60)/ dates.length),
      dates: dates.length
     })
  .addTo(map);

  
  $(".range-labels").children().last().click()
  const last_index = label_dates.length-1;
  $(".range-labels").get(0).innerHTML= '<div class="flex justify-between w-full ">'+label_dates.map((item, index) =>{
    return (` 
    <div class=" flex flex-col items-center ">
      <div class=" flex ">
    
        <div class="ruler-line-day">
        </div>
    
      </div>
      <div class="w-0 overflow-visible ">
        <p class="text-white font-sans text-normal text-xxs text-center ">
        ${item[0]}
        </p>
      </div>
    </div>
    ${index!==last_index ? `<div class="ruler-line-break">
    </div>`:""}`)
  }).join('')+`</div>`;
  
};

  hideTooltips(){
    $(".leaflet-tooltip").hide();
    
  }

  showTooltips(){
    $(".leaflet-tooltip").show();
    
  }


  handleTooltips(e){
    if(e.srcElement.checked === false){
      this.hideTooltips();
    }else{
      this.showTooltips();
    }
  }

  disconnect(){

  }
  

}